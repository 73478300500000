import React from 'react';

import logo from '~/assets/logo-white.png';

import { Container, Body } from './styles';

export default function Empty() {
    return (
        <Container>
            <Body>
                <img src={logo} alt="Pluginbot Logo" title="Pluginbot Logo" />
            </Body>
        </Container>
    );
}
