import React from 'react';
import { Switch } from 'react-router-dom';

import Double3 from '../pages/Double3';
import Empty from '../pages/Empty';
import Exit from '../pages/Exit';
// import Future from '../pages/Future';
import NAOConversation from '../pages/NAO/Conversation';
import InviteEnd from '../pages/RobotInvites/End';
import JoinInvite from '../pages/RobotInvites/Join';
import SD02IndoorDelivery from '../pages/SD02/Indoor';
import Double3Setup from '../pages/Setup/Double3';
// import IoTDelivery from '../pages/Simulator/IoTDelivery';
import Route from './Route';

export default function Router() {
    return (
        <Switch>
            {/* Robot Invites APP */}
            <Route path="/invite/:invite_id/end" component={InviteEnd} />
            <Route path="/invite/:invite_id" component={JoinInvite} />

            {/* Double 3 Telepresence APP */}
            <Route path="/double_3" component={Double3} layout="default" />
            <Route
                path="/telepresence/double_3"
                component={Double3}
                layout="default"
            />

            {/* SD02 Indoor Delivery APP */}
            <Route
                path="/sd02/indoor/:app_id"
                component={SD02IndoorDelivery}
                layout="robot_app"
                selector="sd02_indoor"
            />
            <Route
                path="/indoor/sd02/:app_id"
                component={SD02IndoorDelivery}
                layout="robot_app"
                selector="sd02_indoor"
            />

            {/* NAO Conversation Aux APP */}
            <Route
                path="/conversation/nao/:robot_code"
                component={NAOConversation}
                layout="robot_app"
                selector="nao_conversation"
            />

            {/* FUTURISTIC DESIGN TEST PAGE */}
            {/* <Route
                path="/design/future"
                component={Future}
                layout="future"
                selector="nao_conversation"
            /> */}

            {/* SETUP APP - Double 3 */}
            <Route
                path="/setup/double_3"
                component={Double3Setup}
                layout="default"
            />

            {/* IOT SIMULATOR FOR DELIVERY ROBOTS */}
            {/* <Route
                path="/simulator/delivery/:app_id"
                component={IoTDelivery}
                layout="default"
            /> */}

            {/* Default Pages */}
            <Route path="/exit" component={Exit} layout="default" />
            <Route path="/" component={Empty} layout="default" />
        </Switch>
    );
}
