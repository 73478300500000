/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import AppLocale from '~/lngProvider';
import pluginbot_api from '~/services/pluginbot-api';
import {
    updateAppSettings,
    updateSessions,
} from '~/store/modules/sd02_indoor/actions';

import { App, Body, DarkDialog } from '../styles';
import Content from './Content';
import Login from './Login';

const idle_timeout = 10 * 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SD02Indoor({ match }) {
    const { app_id } = match.params;

    const dispatch = useDispatch();
    const app_state = useSelector(state => state.sd02_indoor || {});
    const u_sessions = app_state.sessions || {};
    const app_session = u_sessions[app_id];

    const [screen, setScreen] = useState(null);
    const [appVisual, setAppVisual] = useState({});
    const [messageDialog, setMessageDialog] = useState(false);

    const isLandscape = screen && screen.orientation === 'landscape';
    const isBigScreen = screen && screen.width >= 1024;

    const getScreenProps = () => {
        const props = {
            ...screen,
        };
        if (window.matchMedia('(orientation: portrait)').matches) {
            props.orientation = 'portrait';
        }

        if (window.matchMedia('(orientation: landscape)').matches) {
            props.orientation = 'landscape';
        }

        const windowW = window.innerWidth;
        props.width = windowW;
        setScreen(props);
    };

    useEffect(() => {
        getScreenProps();
        window.addEventListener('resize', getScreenProps);
    }, []);

    const handleClose = () => {
        setMessageDialog(null);
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <span style={{ color: '#fff !important' }}>
                    {error.response.data.error}
                </span>
            );

            return setMessageDialog({
                title: <LocaleMessage msg="errors.generic" />,
                body: message,
            });
        }
        return setMessageDialog({
            title: <LocaleMessage msg="errors.generic" />,
            body: '',
        });
    }

    async function loadApplication(id) {
        await pluginbot_api
            .get(`/apps/indoor_delivery/${id}/visual`)
            .then(response => {
                const settings = response.data;
                // console.log(settings);
                setAppVisual(settings);
                dispatch(
                    updateAppSettings({
                        ...app_state.settings,
                        [id]: settings,
                    })
                );
            })
            .catch(error => {
                requestError(error);
            });
    }

    useEffect(() => {
        loadApplication(app_id);
    }, [app_id]);

    function renderPopUpMessage(open, message) {
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        width: '70%',
                        backgroundColor: '#111',
                        boxShadow: 'none',
                        paddingBottom: '10px',
                        color: '#fff !important',
                    },
                }}
            >
                <DarkDialog>
                    <DialogTitle disableTypography>
                        {message.title || null}
                        <IconButton
                            onClick={handleClose}
                            style={{
                                marginRight: '0px',
                                paddingRight: '0px',
                                color: '#ddd',
                            }}
                        >
                            <MdClose />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="mb-3">
                        {message.body}
                    </DialogContent>
                </DarkDialog>
            </Dialog>
        );
    }

    function updateAppSession(session) {
        // console.log(session);
        const new_sessions = {
            ...u_sessions,
            [app_id]: session,
        };
        dispatch(updateSessions(new_sessions));
    }

    async function exitSession() {
        const token = app_session && app_session.token ? app_session.token : '';

        if (token) {
            const auth = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            await pluginbot_api
                .post(`/auth/signout`, {}, auth)
                .then(() => {})
                .catch(error => {
                    requestError(error);
                });
        }

        updateAppSession(null);
    }

    return (
        <App visual={appVisual}>
            <>
                <IdleTimer
                    element={document}
                    onIdle={() => {
                        setMessageDialog(null);
                    }}
                    debounce={250}
                    timeout={idle_timeout}
                />
                {messageDialog ? renderPopUpMessage(true, messageDialog) : null}
                <Body>
                    {!app_session ? (
                        <Login
                            visual={appVisual}
                            application={app_id}
                            requestError={e => requestError(e)}
                            updateAppSession={s => updateAppSession(s)}
                            screen={{ isLandscape, isBigScreen }}
                        />
                    ) : (
                        <Content
                            visual={appVisual}
                            session={app_session}
                            application={app_id}
                            exitSession={() => exitSession()}
                            screen={{ isLandscape, isBigScreen }}
                            setPopUp={message => setMessageDialog(message)}
                        />
                    )}
                </Body>
            </>
        </App>
    );
}

SD02Indoor.propTypes = {
    match: PropTypes.object.isRequired,
};
