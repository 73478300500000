export function updateSessions(sessions) {
    return {
        type: '@sd02_indoor/UPDATE_SESSIONS',
        payload: { sessions },
    };
}

export function updateAppSettings(settings) {
    return {
        type: '@sd02_indoor/UPDATE_APP_SETTINGS',
        payload: { settings },
    };
}
