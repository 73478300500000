/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
// import { ToastContainer } from 'react-toastify';

// import { AnimatorGeneralProvider, Animator } from '@arwes/animation';
// import { ArwesThemeProvider, StylesBaseline } from '@arwes/core';
import PropTypes from 'prop-types';

import AppLocale from '~/lngProvider';

// import { Container, Body } from './styles';

// const ROOT_FONT_FAMILY = '"Titillium Web", sans-serif';

const lang_locale = {
    en_US: 'en',
    en_GB: 'enGB',
    pt_BR: 'br',
    es_ES: 'es',
};
// const generalAnimator = { duration: { enter: 200, exit: 200 } };
export default function FutureApp({ match, children, selector }) {
    const { app_id } = match.params;

    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height');
    }

    const all_states = useSelector(state => state[selector] || {});
    const all_settings = all_states.settings || {};
    const app_settings =
        app_id && all_settings[app_id] ? all_settings[app_id] : null;
    const language =
        app_settings && app_settings.language ? app_settings.language : 'en_US';
    const locale =
        language && lang_locale[language] ? lang_locale[language] : 'en';

    const currentAppLocale = AppLocale[locale] || {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
    };

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            {/* <ArwesThemeProvider>
                <StylesBaseline
                    styles={{
                        body: { fontFamily: ROOT_FONT_FAMILY },
                    }}
                />
                <ToastContainer
                    toastClassName="toast-container"
                    autoClose={false}
                    position="top-center"
                    theme="dark"
                />
                <AnimatorGeneralProvider animator={generalAnimator}>
                    <Animator
                        animator={{
                            activate: true,
                            manager: 'stagger',
                        }}
                    >
                        <Container>
                            <Body className="app-main-content">{children}</Body>
                        </Container>
                    </Animator>
                </AnimatorGeneralProvider>
            </ArwesThemeProvider> */}
        </IntlProvider>
    );
}

FutureApp.propTypes = {
    match: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
    selector: PropTypes.string,
};

FutureApp.defaultProps = {
    selector: '',
};
