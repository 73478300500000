/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
    sessions: {},
    settings: {},
};

export default function sd02_indoor(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@sd02_indoor/UPDATE_SESSIONS': {
                const { sessions } = action.payload;
                draft.sessions = sessions;
                break;
            }
            case '@sd02_indoor/UPDATE_APP_SETTINGS': {
                const { settings } = action.payload;
                draft.settings = settings;
                break;
            }
            default:
                return state;
        }
    });
}
