/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MdGpsFixed } from 'react-icons/md';
import reactImageSize from 'react-image-size';
import { TOOL_PAN, ReactSVGPanZoom } from 'react-svg-pan-zoom';

import PropTypes from 'prop-types';

import { MapArea } from './styles';

export default function SVGMap({ image, height, points, activePoint }) {
    const Viewer = useRef(null);

    const [imgProps, setImgProps] = useState({ width: 1000, height: 1000 });
    const [viewSize, setViewSize] = useState({ width: 100, height: 100 });
    const [transformProps, setTransformProps] = useState({
        scale: 1,
    });

    const [tool, setTool] = useState(TOOL_PAN);
    const [value, setValue] = useState({});

    async function getMeta(url) {
        const imgSize = await reactImageSize(url);
        setImgProps(imgSize);
    }

    useLayoutEffect(() => {
        function updateSize() {
            const v_w = Viewer.current.offsetWidth || 1;
            const v_h = Viewer.current.offsetHeight || 1;
            setViewSize({
                width: v_w,
                height: v_h,
            });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        getMeta(image);
    }, [image]);

    const iconSize = 40;

    function updateValues(v) {
        const { a } = v;
        setTransformProps({ scale: a });
        setValue(v);
    }

    function renderPoint(p) {
        const scale = transformProps.scale || 1;
        const size = iconSize / (1.5 * scale);

        const p_x = p.x - size / 2;
        const p_y = p.y - size;

        const fill = p.id === activePoint ? 'red' : 'black';

        return p_x && p_y ? (
            <g key={p.id}>
                <text
                    x={p_x}
                    y={p_y - size / 2}
                    fill={fill}
                    dominantBaseline="middle"
                    fontSize={`${size}px`}
                >
                    {p.label}
                </text>
                <MdGpsFixed
                    x={p_x}
                    y={p_y + size / 2}
                    fill={fill}
                    fontSize={`${size}px`}
                />
            </g>
        ) : null;
    }

    function renderTests() {
        // const w = viewSize.width;
        // const h = viewSize.height;

        // const img_w = imgProps.width || 100;
        // const img_h = imgProps.height || 100;

        // const s_w = w / img_w;
        // const s_h = h / img_h;

        // const scale = s_w <= s_h ? s_w : s_h;
        // const offset_w = (w - img_w * scale) / 2;
        // const offset_h = (h - img_h * scale) / 2;

        // console.log({ w, h, img_w, img_h, s_w, s_h, offset_w, offset_h });

        return (
            <>
                {/* {renderPoint({
                    id: 'cc',
                    label: 'CC',
                    x: w / 2,
                    y: h / 2,
                })} */}
                {/* {renderPoint({
                    id: 'ci',
                    label: 'Ci',
                    x: 260.5 * s_w,
                    y: 260.5 * s_h,
                })} */}
                {/* {renderPoint({
                    id: 'tl',
                    label: 'TL',
                    x: 0,
                    y: 0,
                })}
                {renderPoint({
                    id: 'bl',
                    label: 'BL',
                    x: 0,
                    y: h,
                })}
                {renderPoint({
                    id: 'tr',
                    label: 'TR',
                    x: w,
                    y: 0,
                })}
                {renderPoint({
                    id: 'br',
                    label: 'BR',
                    x: w,
                    y: h,
                })} */}
                {/* {renderPoint({
                    id: 'h',
                    label: 'OH',
                    x: 0,
                    y: img_h / 2,
                })}
                {renderPoint({
                    id: 'w',
                    label: 'OW',
                    x: img_w / 2,
                    y: 0,
                })}
                {renderPoint({
                    id: 'offh',
                    label: 'OFFH',
                    x: 0,
                    y: offset_h,
                })}
                {renderPoint({
                    id: 'offw',
                    label: 'OFFW',
                    x: offset_w,
                    y: 0,
                })} */}
                {/* {renderPoint({
                    id: 'l8sy1rxj5TZeLFHiDVp9ivkHWqWxb',
                    label: '[76DIXW]',
                    x: w / 2 + r_pos.x * s_w,
                    y: h / 2 + r_pos.y * s_h,
                })} */}
            </>
        );
    }

    function renderRobots() {
        const w = viewSize.width;
        const h = viewSize.height;

        const img_w = imgProps.width || 100;
        const img_h = imgProps.height || 100;

        const s_w = w / img_w;
        const s_h = h / img_h;

        const scale = s_w <= s_h ? s_w : s_h;

        return (
            <>
                {points.map(p => {
                    return p.x && p.y
                        ? renderPoint({
                              id: p.id,
                              label: p.label,
                              x: w / 2 + p.x * 10 * scale,
                              y: h / 2 - p.y * 10 * scale,
                          })
                        : null;
                })}
            </>
        );
    }

    function renderIllustration() {
        const w = viewSize.width || 500;
        const h = viewSize.height || 500;
        return (
            <>
                <ReactSVGPanZoom
                    width={w}
                    height={h}
                    tool={tool}
                    value={value}
                    onChangeTool={setTool}
                    onChangeValue={v => {
                        updateValues(v);
                    }}
                    scaleFactor={3}
                    scaleFactorOnWheel={1.25}
                >
                    <svg width={w} height={h}>
                        <image href={image} width={w} height={h} />
                        {renderRobots()}
                        {renderTests()}
                    </svg>
                </ReactSVGPanZoom>
            </>
        );
    }

    return (
        <MapArea {...(height && { height })} ref={Viewer}>
            {renderIllustration()}
        </MapArea>
    );
}

SVGMap.defaultProps = {
    points: [],
    onMouseMove: () => {},
    onMouseUp: () => {},
    activePoint: null,
    height: '100%',
    image: '',
};

SVGMap.propTypes = {
    image: PropTypes.string,
    points: PropTypes.array,
    height: PropTypes.string,
    onMouseUp: PropTypes.func,
    onMouseMove: PropTypes.func,
    activePoint: PropTypes.string,
};
