/* eslint-disable no-lonely-if */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { IoLockOpenSharp, IoLockClosedSharp } from 'react-icons/io5';
import {
    MdClose,
    MdCheck,
    MdSwapVert,
    MdPortableWifiOff,
    MdBatteryFull,
} from 'react-icons/md';
import { VscWarning } from 'react-icons/vsc';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import pluginbot_synkar from '~/services/pluginbot-synkar';

import { DarkDialog } from '../../../styles';
import { syncMap } from '../../Service';
import {
    Body,
    MainArea,
    ListsBody,
    RobotInfos,
    ActionsArea,
    MissionLayout,
    MissionDialog,
} from './styles';

const MISSION_DELIVERY = 'deliver_to_landmark';
const MISSION_NAVIGATION = 'go_to_landmark';
const MISSION_CONTAINER = 'open_container';

const alerts = {
    low_battery: {
        critical: false,
    },
    lost_position: {
        critical: true,
    },
    connectivity_error: {
        critical: true,
    },
    base_error: {
        critical: true,
    },
};

export default function RobotDelivery({
    imgs,
    screen,
    visual,
    robot,
    status,
    application,
    requestError,
    clearRobotSelection,
    auth,
    lastNotification,
    sendNotification,
}) {
    const pluginspaceSettings = visual.pluginspace || {};
    const pageSettings = visual.delivery_page || {};

    const r_code = robot ? robot.code : '----';
    const r_name = robot ? robot.name : '----';

    const [currOperation, setCurrOperation] = useState(
        status && status.data && status.data.operation
            ? status.data.operation
            : null
    );

    const [appStatus, setAppStatus] = useState(
        currOperation && currOperation.mission && currOperation.mission.id
            ? 'on_mission'
            : 'waiting'
    );
    const [robotStatus, setRobotStatus] = useState(status.data || {});

    const [isLoading, setIsLoading] = useState(false);

    const [missionType, setMissionType] = useState(null);
    const [missionData, setMissionData] = useState(null);
    const [missionUpdateOperation, setMissionUpdateOperation] = useState('');
    const [showDestinations, setShowDestinations] = useState(false);
    const [showMissionConfirmation, setShowMissionConfirmation] = useState(
        false
    );

    const isConnected = status.status && status.status !== 'disconnected';

    const r_id = (
        <span className="big-text mt-5">{`[${r_code}] ${r_name}`}</span>
    );

    async function getRobotMission(mission_id) {
        if (!mission_id) {
            return setAppStatus('ready');
        }
        return true;
    }

    function parseRobotStatus() {
        if (!status.status || status.status === 'disconnected') {
            clearRobotSelection();
            requestError({
                response: {
                    data: {
                        error: <LocaleMessage msg="errors.robot_offline" />,
                    },
                },
            });
            return false;
        }

        const status_data = status.data || {};
        const operation = status_data.operation || {};

        const op_mission =
            operation && operation.mission ? operation.mission : null;
        if (status.status === 'occupied' || status.status === 'alert') {
            if (op_mission && op_mission.id) {
                setAppStatus('on_mission');
                // getRobotMission(op_mission.id);
                return true;
            }
        }

        return setTimeout(() => {
            setAppStatus('ready');
        }, 500);
    }

    async function getRobotStatus(robot_id) {
        const url = `robots/${robot_id}/status`;

        await pluginbot_synkar
            .get(url, auth)
            .then(response => {
                const r_status = response.data;
                parseRobotStatus(r_status);
            })
            .catch(() => {
                parseRobotStatus(null);
            });
        return true;
    }

    useEffect(() => {
        if (robot) {
            getRobotStatus(robot.id);
        }
    }, [robot]);

    useEffect(() => {
        const status_data = status && status.data ? status.data : {};
        setRobotStatus(status_data);
        setCurrOperation(status_data.operation);
        setAppStatus(status_data.operation ? 'on_mission' : 'ready');
        if (status_data.operation) {
            setIsLoading(false);
        }
    }, [status]);

    useEffect(() => {
        const lastNotificationData =
            lastNotification && lastNotification.data
                ? lastNotification.data
                : {};
        if (
            lastNotificationData &&
            lastNotificationData.robot_id === robot.id &&
            currOperation
        ) {
            // setIsLoading(true);
        }
    }, [lastNotification]);

    // ROBOT FUNCTIONS
    async function syncRobot() {
        setIsLoading(true);
        await pluginbot_synkar
            .post(`robots/${robot.id}/sync`, {}, auth)
            .then(async response => {
                const { data } = response;
                const app_config = data.app_config || {};
                if (!app_config.map) return;
                const app_map = app_config.map.reference;
                await syncMap(data.synkar_id, app_map);
            })
            .catch(() => {});
        setIsLoading(false);
    }

    async function startMission(parameters) {
        // console.log('start mission');
        setAppStatus('on_mission');
        setShowDestinations(false);
        setShowMissionConfirmation(false);

        const app_map = application.map || {};

        const delivery_parameters = {
            map: {
                id: app_map.id,
                name: app_map.name,
            },
            destination: parameters,
        };

        const data = {
            robot_id: robot.id,
            mission_type: missionType,
            parameters: delivery_parameters,
        };

        setIsLoading(true);
        await pluginbot_synkar
            .post(`missions`, data, auth)
            .then(response => {
                const mission = response.data;
                const { id: mission_id } = mission;
                getRobotMission(mission_id);
            })
            .catch(() => {});
        return true;
    }

    async function updateMission(operation, mission_id) {
        // console.log(`${operation} delivery`);
        const deliveryMission =
            currOperation && currOperation.mission ? currOperation.mission : {};

        setIsLoading(true);
        await pluginbot_synkar
            .post(`missions/${mission_id}/${operation}`, {}, auth)
            .then(response => {
                return response;
            })
            .catch(() => {
                return false;
            });

        setMissionUpdateOperation('');

        sendNotification({
            type: `update_mission`,
            body: {
                operation,
                robot_id: robot.id,
                alert_id: deliveryMission.id,
            },
        });
        setIsLoading(false);
    }

    async function openContainer() {
        // console.log('open container');
        const data = {
            robot_id: robot.id,
            mission_type: MISSION_CONTAINER,
        };

        setIsLoading(true);
        await pluginbot_synkar
            .post(`missions`, data, auth)
            .then(() => {})
            .catch(() => {});
        setIsLoading(false);
        return true;
    }

    function prepareDeliveryMission(data) {
        setMissionData(data);
        setShowMissionConfirmation(true);
    }

    function clearMissionData() {
        setMissionData(null);
        setShowMissionConfirmation(false);
    }

    // =========================================================================

    function renderMissionUpdateOperation(operation_name) {
        return operation_name && currOperation ? (
            <Dialog
                open={!!operation_name}
                maxWidth="md"
                keepMounted
                onClose={() => setMissionUpdateOperation('')}
                PaperProps={{
                    style: {
                        width: '100%',
                        backgroundColor: '#00000000',
                        boxShadow: 'none',
                        color: '#fff',
                    },
                }}
            >
                <MissionDialog colors={{}} screen={screen}>
                    <Card
                        style={{
                            height: '100%',
                        }}
                    >
                        <CardContent className="mt-5 mb-5">
                            <LocaleMessage
                                msg={`app.indoor_delivery.mission.${operation_name}.confirmation`}
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() => setMissionUpdateOperation('')}
                            >
                                <MdClose size={28} />
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() =>
                                    updateMission(
                                        operation_name,
                                        currOperation && currOperation.mission
                                            ? currOperation.mission.id
                                            : ''
                                    )
                                }
                            >
                                <MdCheck size={28} />
                            </Button>
                        </CardActions>
                    </Card>
                </MissionDialog>
            </Dialog>
        ) : null;
    }

    function renderMissionButton(label_key, update, allowed = false) {
        return (
            <Button
                fullWidth
                color="primary"
                size="large"
                disableElevation
                onClick={() => {
                    setMissionUpdateOperation(update);
                }}
                disabled={!allowed}
                variant={allowed ? 'contained' : 'outlined'}
            >
                <LocaleMessage msg={label_key} />
            </Button>
        );
    }

    function renderCurrMission() {
        const currStatus =
            currOperation && currOperation.status ? currOperation.status : '';
        const deliveryMission =
            currOperation && currOperation.mission ? currOperation.mission : {};
        const deliveryData = deliveryMission.parameters || {};
        const { destination } = deliveryData;
        const two_items = ['paused', 'running'].includes(currStatus);
        // const two_items = true;

        return (
            <MissionLayout className="col-12 p-3" visual={visual}>
                {missionUpdateOperation
                    ? renderMissionUpdateOperation(missionUpdateOperation)
                    : null}
                <div>{r_id}</div>
                <Typography className="mission-destination">
                    {destination ? destination.name : ''}
                </Typography>
                <Typography>
                    {currStatus ? (
                        <LocaleMessage
                            msg={`app.indoor_delivery.mission.status.${currStatus}`}
                        />
                    ) : null}
                </Typography>
                {!['waiting', 'finished'].includes(currStatus) ? (
                    <div className="options row mb-0 px-3">
                        <div
                            className={`${
                                !two_items ? 'col-md-6' : 'col-md-4'
                            }  col-12 mb-3`}
                        >
                            {renderMissionButton(
                                'app.indoor_delivery.mission.pause',
                                'pause',
                                ['running'].includes(currStatus)
                            )}
                        </div>
                        {two_items ? (
                            <div className="col-md-4 col-12 mb-3">
                                {renderMissionButton(
                                    'app.indoor_delivery.mission.cancel',
                                    'cancel',
                                    ['paused', 'running'].includes(currStatus)
                                )}
                            </div>
                        ) : null}
                        <div
                            className={`${
                                !two_items ? 'col-md-6' : 'col-md-4'
                            }  col-12 mb-3`}
                        >
                            {renderMissionButton(
                                'app.indoor_delivery.mission.retry',
                                currStatus === 'paused' ? 'resume' : 'retry',
                                ['paused', 'alert', 'aborted'].includes(
                                    currStatus
                                )
                            )}
                        </div>
                    </div>
                ) : null}
                {/* <div className="options row mb-0 px-3">
                    <div
                        className={`${
                            !two_items ? 'col-md-6' : 'col-md-4'
                        }  col-12 mb-3`}
                    >
                        {renderMissionButton(
                            'app.indoor_delivery.mission.pause',
                            'pause',
                            ['running'].includes(currStatus)
                        )}
                    </div>
                    {two_items ? (
                        <div className="col-md-4 col-12 mb-3">
                            {renderMissionButton(
                                'app.indoor_delivery.mission.cancel',
                                'cancel',
                                ['paused', 'running'].includes(currStatus)
                            )}
                        </div>
                    ) : null}
                    <div
                        className={`${
                            !two_items ? 'col-md-6' : 'col-md-4'
                        }  col-12 mb-3`}
                    >
                        {renderMissionButton(
                            'app.indoor_delivery.mission.retry',
                            currStatus === 'paused' ? 'resume' : 'retry',
                            ['paused', 'alert', 'aborted'].includes(currStatus)
                        )}
                    </div>
                </div> */}
            </MissionLayout>
        );
    }

    function renderMissionConfirmation() {
        return showMissionConfirmation && missionData ? (
            <Dialog
                open={showMissionConfirmation}
                maxWidth="md"
                keepMounted
                onClose={() => setShowMissionConfirmation(false)}
                PaperProps={{
                    style: {
                        width: '100%',
                        backgroundColor: '#00000000',
                        boxShadow: 'none',
                        color: '#fff',
                    },
                }}
            >
                <MissionDialog
                    colors={{}}
                    screen={screen}
                    bigFontSize={screen.isLandscape && !screen.isBigScreen}
                >
                    <Card
                        style={{
                            height: '100%',
                        }}
                    >
                        <CardHeader
                            title={
                                missionType === MISSION_DELIVERY ? (
                                    <LocaleMessage msg="app.indoor_delivery.action.start_delivery.confirmation" />
                                ) : (
                                    <LocaleMessage msg="app.indoor_delivery.action.start_navigation.confirmation" />
                                )
                            }
                        />

                        <CardContent>
                            <Typography className="card-content">
                                {missionData.name}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() => clearMissionData()}
                            >
                                <MdClose size={28} />
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() => startMission(missionData)}
                            >
                                <MdCheck size={28} />
                            </Button>
                        </CardActions>
                    </Card>
                </MissionDialog>
            </Dialog>
        ) : null;
    }

    function renderDestinationButton(destination) {
        return (
            <div
                className="col-md-3 col-6 mb-5"
                key={`destination_${destination.id}`}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                        prepareDeliveryMission(destination);
                    }}
                >
                    {destination.name}
                </Button>
            </div>
        );
    }

    function renderDestinations() {
        const items =
            application && application.map && application.map.points
                ? application.map.points
                : [];

        return (
            <Dialog
                maxWidth="lg"
                open={showDestinations}
                keepMounted
                onClose={() => setShowDestinations(false)}
                fullWidth
                PaperProps={{
                    style: {
                        height: '90vh',
                        width: '100%',
                        backgroundColor: '#111',
                        boxShadow: 'none',
                        paddingBottom: '10px',
                        color: '#fff !important',
                    },
                }}
            >
                <DarkDialog>
                    <DialogTitle disableTypography>
                        <span>
                            {missionType === MISSION_DELIVERY ? (
                                <LocaleMessage msg="app.indoor_delivery.action.start_delivery" />
                            ) : (
                                <LocaleMessage msg="app.indoor_delivery.action.start_navigation" />
                            )}
                        </span>
                        <IconButton
                            onClick={() => setShowDestinations(false)}
                            style={{
                                color: '#ddd',
                            }}
                        >
                            <MdClose size={20} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="mb-3">
                        {renderMissionConfirmation()}
                        <ListsBody
                            visual={visual}
                            bigFontSize={
                                screen.isLandscape && !screen.isBigScreen
                            }
                        >
                            <div className="mb-5 helper">
                                {missionType === MISSION_DELIVERY ? (
                                    <LocaleMessage msg="app.indoor_delivery.action.start_delivery.helper" />
                                ) : null}
                            </div>
                            <div className="row mb-1" style={{ width: '100%' }}>
                                {items.map(d => {
                                    return renderDestinationButton(d);
                                })}
                            </div>
                        </ListsBody>
                    </DialogContent>
                </DarkDialog>
            </Dialog>
        );
    }

    function renderRobot() {
        const r_type = robot.type || {};
        const img = imgs[r_type.slug];

        const Img = <img src={img} alt={r_type.name} title={r_type.name} />;

        return screen.isLandscape && !screen.isBigScreen ? (
            <>
                <div className="col-8 item pluginbot-robot">
                    {Img}
                    <div className="mt-5">{r_id}</div>
                </div>
            </>
        ) : (
            <>
                <div className="col-12 item mt-5 pluginbot-robot">
                    {Img}
                    <div className="mt-5">{r_id}</div>
                </div>
            </>
        );
    }

    function renderRobotInfos() {
        const { battery, container } = robotStatus;
        const isContainerOpen =
            container && container.is_open ? container.is_open : false;

        const ConnIcon = isConnected ? MdSwapVert : MdPortableWifiOff;
        const LockIcon = isContainerOpen ? IoLockOpenSharp : IoLockClosedSharp;

        const connInfo = (
            <div
                className={`item ${
                    screen.isLandscape && !screen.isBigScreen ? 'col-4' : ''
                }`}
            >
                <ConnIcon size={28} className="mb-3" />
                <span>
                    <LocaleMessage msg="labels.status.upper" />
                    <br />
                    <LocaleMessage
                        msg={`rc3.status.${
                            isConnected ? 'connected' : 'disconnected'
                        }`}
                    />
                </span>
            </div>
        );

        const batteryInfo = (
            <div
                className={`item ${
                    screen.isLandscape && !screen.isBigScreen ? 'col-4' : ''
                }`}
            >
                <MdBatteryFull size={28} className="mb-3" />
                <span>
                    <LocaleMessage msg="labels.battery.upper" />
                    <br />
                    {battery && battery.level ? `${battery.level} %` : '---'}
                </span>
            </div>
        );

        const lockInfo = (
            <div
                className={`item ${
                    screen.isLandscape && !screen.isBigScreen ? 'col-4' : ''
                }`}
            >
                <LockIcon size={28} className="mb-3" />
                <span>
                    <LocaleMessage msg="app.indoor_delivery.container" />
                    <br />
                    <LocaleMessage
                        msg={`app.indoor_delivery.container.${
                            isContainerOpen ? 'open' : 'locked'
                        }`}
                    />
                </span>
            </div>
        );

        return (
            <RobotInfos className="row" screen={screen}>
                <div
                    className={`${
                        screen.isLandscape && !screen.isBigScreen
                            ? 'col-4 item-column'
                            : 'col-12 item-row mt-3'
                    }`}
                >
                    <>
                        {connInfo}
                        {isConnected ? (
                            <>
                                {batteryInfo}
                                {lockInfo}
                            </>
                        ) : null}
                    </>
                </div>
                {renderRobot()}
            </RobotInfos>
        );
    }

    function renderStandbyOptions() {
        const ps_logo = pageSettings.show_logo ? (
            <img
                className="pluginspace-logo mb-3"
                src={
                    pluginspaceSettings.logo && pluginspaceSettings.logo.url
                        ? pluginspaceSettings.logo.url
                        : ''
                }
                alt="Pluginspace Logo"
                title="Pluginspace Logo"
            />
        ) : null;
        const curr_alert = robotStatus.alert;
        const need_sync =
            curr_alert && alerts[curr_alert] && alerts[curr_alert].critical
                ? alerts[curr_alert].critical
                : false;
        const alert_size =
            !screen.isLandscape || screen.isBigScreen ? '4vh' : '6vh';

        return (
            <>
                {showDestinations ? renderDestinations() : null}
                {ps_logo}
                <div
                    className={`options ${
                        screen.isLandscape && screen.isBigScreen ? 'px-5' : ''
                    } `}
                >
                    {isConnected && curr_alert ? (
                        <div className="col-12 mb-3 alert">
                            <VscWarning
                                style={{
                                    fontSize: alert_size,
                                }}
                            />
                            <span className="big-text">
                                <LocaleMessage
                                    msg={`app.indoor_delivery.alert.${curr_alert}`}
                                />
                            </span>
                            <VscWarning
                                style={{
                                    fontSize: alert_size,
                                }}
                            />
                        </div>
                    ) : null}
                    {need_sync ? (
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            className="mt-3 mb-3"
                            onClick={() => syncRobot()}
                            disabled={!isConnected}
                        >
                            <LocaleMessage msg="app.indoor_delivery.action.sync_robot" />
                        </Button>
                    ) : (
                        <>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="mt-3 mb-3"
                                onClick={() => {
                                    setMissionType(MISSION_NAVIGATION);
                                    setShowDestinations(true);
                                }}
                                disabled={!isConnected}
                            >
                                <LocaleMessage msg="app.indoor_delivery.action.call_robot" />
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="mt-3 mb-3"
                                onClick={() => {
                                    openContainer();
                                }}
                                disabled={!isConnected}
                            >
                                <LocaleMessage msg="app.indoor_delivery.action.open_container" />
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="mt-3 mb-3"
                                onClick={() => {
                                    setMissionType(MISSION_DELIVERY);
                                    setShowDestinations(true);
                                }}
                                // disabled={!isConnected || isContainerOpen}
                                disabled={!isConnected}
                            >
                                <LocaleMessage msg="app.indoor_delivery.action.start_delivery" />
                            </Button>
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderStandby() {
        return (
            <MainArea screen={screen}>
                <div
                    className={`py-3 ${
                        screen.isLandscape ? 'col-12 row' : 'portrait'
                    }`}
                >
                    <div
                        className={`${
                            screen.isLandscape ? 'col-5' : ''
                        } container robot-infos`}
                    >
                        {renderRobotInfos()}
                    </div>
                    <ActionsArea
                        className={`${
                            screen.isLandscape ? 'col-7' : ''
                        } container px-3`}
                    >
                        {renderStandbyOptions()}
                    </ActionsArea>
                </div>
            </MainArea>
        );
    }

    function renderWaiting() {
        const lastNotificationData =
            lastNotification && lastNotification.data
                ? lastNotification.data
                : {};
        return (
            <MissionLayout className="col-12 p-3" visual={visual}>
                <ReactLoading type="bars" height="10vh" width="10vw" />
                {lastNotification &&
                lastNotification.type === 'update_mission' &&
                lastNotificationData.robot_id === robot.id ? (
                    <LocaleMessage msg="app.indoor_delivery.notification.mission_update" />
                ) : null}
            </MissionLayout>
        );
    }

    const content = {
        waiting: renderWaiting(),
        ready: renderStandby(),
        on_mission: renderCurrMission(),
    };

    return (
        <Body screen={screen} visual={visual}>
            {isLoading ? (
                renderWaiting()
            ) : (
                <>{content[appStatus] ? content[appStatus] : null}</>
            )}
        </Body>
    );
}

RobotDelivery.propTypes = {
    imgs: PropTypes.object.isRequired,
    robot: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    clearRobotSelection: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    status: PropTypes.object,
    visual: PropTypes.object,
    lastNotification: PropTypes.object,
    sendNotification: PropTypes.func.isRequired,
};

RobotDelivery.defaultProps = {
    status: {},
    visual: {},
    lastNotification: null,
};
