/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import PropTypes from 'prop-types';

import { IconButton, Badge } from '@material-ui/core';

import history from '~/services/history';

import { UserAvatar } from './styles';
import UserInfoPopup from './UserInfoPopup/index';

export default function UserInfo({
    profile,
    onLogoutClick,
    showStatus,
    showStatusProfilePic,
    status,
}) {
    const [openMenu, setOpenMenu] = useState(false);
    const { avatar } = profile;

    function onCloseMenu(link) {
        setOpenMenu(false);
        history.push(link);
    }

    return (
        <UserAvatar className="user-profile d-flex flex-row align-items-center">
            <Dropdown
                className="quick-menu"
                isOpen={openMenu}
                toggle={() => setOpenMenu(!openMenu)}
            >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                >
                    <IconButton
                        className={`icon-btn size-30 ${
                            showStatus ? `status-${status}` : ''
                        }`}
                    >
                        <Badge
                            color="secondary"
                            variant="dot"
                            invisible={!showStatus}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {showStatusProfilePic && avatar && avatar.url ? (
                                <img
                                    className="avatar-placeholder"
                                    alt="..."
                                    src={avatar ? avatar.url : null}
                                />
                            ) : (
                                <div className="MuiAvatar-root bg-secondary size-30 text-white MuiAvatar-colorDefault rounded-circle">
                                    {profile && profile.name
                                        ? profile.name[0].toUpperCase()
                                        : 'P'}
                                </div>
                            )}
                        </Badge>
                    </IconButton>
                </DropdownToggle>

                <DropdownMenu end style={{ width: '250px', top: '15px' }}>
                    <UserInfoPopup
                        profile={profile}
                        onCloseMenu={link => onCloseMenu(link)}
                        onLogoutClick={event => onLogoutClick(event)}
                        showStatusProfilePic={showStatusProfilePic}
                    />
                </DropdownMenu>
            </Dropdown>
        </UserAvatar>
    );
}

UserInfo.propTypes = {
    onLogoutClick: PropTypes.func.isRequired,
    profile: PropTypes.object,
    showStatus: PropTypes.bool,
    showStatusProfilePic: PropTypes.bool,
    status: PropTypes.string,
};

UserInfo.defaultProps = {
    profile: {},
    showStatus: false,
    showStatusProfilePic: false,
    status: 'disconnected',
};
