/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import PropTypes from 'prop-types';

import Splash from '~/components/Splash/Outside';

import AppLocale from '~/lngProvider';

import { Container, Body } from './styles';

export default function Default({ children }) {
    const isLoading = false;

    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height');
    }

    const { locale } = useSelector(({ settings }) => settings);

    const currentAppLocale = AppLocale[locale ? locale.locale : 'en'] || {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
    };

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <>
                <ToastContainer
                    toastClassName="toast-container"
                    autoClose={3000}
                />
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <Container>
                            <Body className="app-main-content">{children}</Body>
                        </Container>
                    </>
                )}
            </>
        </IntlProvider>
    );
}

Default.propTypes = {
    children: PropTypes.element.isRequired,
};

Default.defaultProps = {};
