export function updateRobotID(ID) {
    return {
        type: '@robot/UPDATE_ID',
        payload: { ID },
    };
}

export function updateRobotStatus(status) {
    return {
        type: '@robot/UPDATE_STATUS',
        payload: { status },
    };
}

export function updateRobotPassword(password) {
    return {
        type: '@robot/UPDATE_PASSWORD',
        payload: { password },
    };
}
