/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import AlertExtras from '../Extras';
import { Title, Container } from '../styles';

export default function UpdatedDelivery({ robot, content, removeAlert }) {
    function onAlertClick() {
        removeAlert();
    }

    const { map_layer, point } = content;
    const delivery_destination = `${map_layer ? map_layer.name : ''} ${
        map_layer && point && point.name ? ` - ${point.name}` : ''
    }`;

    const extras = [
        {
            key: 'destination',
            value: delivery_destination,
        },
    ];

    return (
        <Container
            onClick={() => {
                onAlertClick();
            }}
        >
            <Title className="col-12 p-1">
                <strong>
                    <LocaleMessage msg="app.indoor_delivery.notification.mission_update" />
                </strong>
            </Title>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}
            >
                <span className="name">
                    [{robot.code}] {robot.name}
                </span>
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}>
                <AlertExtras infos={extras} />
            </div>
        </Container>
    );
}

UpdatedDelivery.propTypes = {
    robot: PropTypes.object,
    content: PropTypes.object,
    removeAlert: PropTypes.func.isRequired,
};

UpdatedDelivery.defaultProps = {
    robot: {},
    content: {},
};
