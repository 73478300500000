import styled from 'styled-components';

export const Body = styled.div`
    width: 100%;
    padding: 20px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;

    img {
        max-width: 60%;
        max-height: 200px;
    }
`;

export const Title = styled.div`
    h2 {
        text-align: center;
        color: #fff;
        font-weight: bold;
        margin-bottom: 0px;
    }
`;
