export function updateLastStatus(status) {
    return {
        type: '@telepresence_app/UPDATE_LAST_STATUS',
        payload: { status },
    };
}

export function updateAppStatus(status) {
    return {
        type: '@telepresence_app/UPDATE_APP_STATUS',
        payload: { status },
    };
}

export function updateCallSession(session) {
    return {
        type: '@telepresence_app/UPDATE_CALL_SESSION',
        payload: { session },
    };
}

export function updateAppSettings(settings) {
    return {
        type: '@telepresence_app/UPDATE_APP_SETTINGS',
        payload: { settings },
    };
}
