/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
    last_status: 'disconnected',
    status: 'disconnected',
    session: {},
    settings: {},
};

export default function telepresence_app(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@telepresence_app/UPDATE_APP_SETTINGS': {
                const { settings } = action.payload;
                draft.settings = settings;
                break;
            }
            case '@telepresence_app/UPDATE_LAST_STATUS': {
                const { status } = action.payload;
                draft.last_status = status;
                break;
            }
            case '@telepresence_app/UPDATE_APP_STATUS': {
                const { status } = action.payload;
                draft.status = status;
                break;
            }
            case '@telepresence_app/UPDATE_CALL_SESSION': {
                const { session } = action.payload;
                draft.session = session;
                break;
            }
            default:
                return state;
        }
    });
}
