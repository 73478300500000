import styled from 'styled-components';

export const ConnectionPage = styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .min-50 {
        /* min-height: 50%; */
    }

    background: ${props => {
        const { colors } = props.visual;
        const c1 = colors ? colors.top : '#000';
        const c2 = colors ? colors.bottom : '#000';
        return `linear-gradient(180deg, ${c1}, ${c2})`;
    }};
`;

export const ImgArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: ${props => {
        const screen = props.screen || {};
        const isLandscape = screen.isLandscape || false;
        return isLandscape ? '5vh' : '2vh';
    }};
    height: ${props => {
        const screen = props.screen || {};
        const isLandscape = screen.isLandscape || false;
        return isLandscape ? '100%' : '40%';
    }};

    .pluginspace-logo {
        max-height: 10%;
        max-width: 40%;
    }

    .extra-img {
        max-height: 80%;
        max-width: 80%;
        object-fit: contain;

        width: ${props => {
            const { media } = props.settings;
            const w = media ? media.width : '50%';
            return w;
        }};
    }
`;

export const LoginForm = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 5vh;

    height: ${props => {
        const screen = props.screen || {};
        const isLandscape = screen.isLandscape || false;
        return isLandscape ? '100%' : '40%';
    }};

    min-height: ${props => {
        const screen = props.screen || {};
        const isLandscape = screen.isLandscape || false;
        return isLandscape ? '100%' : '40%';
    }};

    background-color: #fff;

    img {
        max-height: 10vh;
        max-width: 40%;
    }

    .inputs {
        max-width: 500px;
    }
`;
