import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 5vh 0px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: 'Roboto', sans-serif !important;

    background: ${props => {
        const def_color = props.theme.palette.primary.main;
        const colors =
            props.visual && props.visual.colors ? props.visual.colors : {};

        const bg_color = colors.primary ? colors.primary : def_color;
        const bottom_color = darken(0.25, bg_color);
        return `linear-gradient(180deg, ${bg_color}, ${colors.bottom ||
            bottom_color})`;
    }};
`;

export const Head = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;

    img {
        width: 60%;
        max-height: 200px;
        max-width: 400px;
    }
`;

export const Body = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    font-size: 1.75vh;

    .main-obj {
        min-width: 350px;
        width: 60%;
        height: 50vh;
        padding: 2.5vh;
        margin: 5vh 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;

        .full-size {
            height: 100%;
            min-width: 80%;
        }

        .centered {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }

    .colored-button {
        color: #fff;
        background-color: ${props => {
            const theme = props.theme ? props.theme.palette : {};
            const colors = props.colors ? props.colors : {};
            const { primary } = theme;
            return colors.primary || primary.main;
        }};
    }
`;

export const Title = styled.div`
    font-size: 2vh;
    h2 {
        text-align: center;
        color: #fff;
        font-weight: bold;
        margin-bottom: 0px;
    }
`;

export const SubTitle = styled.div`
    font-size: 1.75vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const Footer = styled.div`
    width: 100%;
    padding: 10px;
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    color: #fff;

    img {
        max-width: 25%;
        max-height: 60px;
    }
`;
