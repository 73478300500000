/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdClose, MdCallEnd, MdCall } from 'react-icons/md';
import IdleTimer from 'react-idle-timer';
import Sound from 'react-sound';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Chip,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import logo from '~/assets/logo-white.png';
import calling_sound from '~/assets/sounds/calling.ogg';
import hangout_sound from '~/assets/sounds/hangout.wav';
import joining_sound from '~/assets/sounds/notification.wav';
import api from '~/services/pluginbot-api';

import {
    Container,
    Title,
    Call,
    ImgContainer,
    ListsBody,
    ContactHeader,
    ContactList,
    ContactCard,
} from './styles';

const call_timeout = 45 * 1000;
const standby_timeout = 15 * 1000;
const connection_timeout = 30 * 1000;
const contact_timeout = 30 * 1000;

const size = '10vw';

const NOTIFICATION_CALL_ACCEPTED = 'call_accepted';
const NOTIFICATION_CALL_CANCELLED = 'call_cancelled';
const NOTIFICATION_CALL_TIMEOUT = 'call_timeout';

export default function Telepresence({
    token,
    status,
    settings,
    sendAlert,
    sendNotification,
    lastNotification,
    telepresenceStatus,
    requestError,
    lastAction,
    clearActions,
    RC3Connected,
}) {
    const api_options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const {
        text,
        image,
        colors,
        allow_requests,
        request_mode,
        main,
        broadcast_text,
        enable_sounds,
    } = settings;

    const [appState, setAppState] = useState('standby');
    const [calling, setCalling] = useState(false);
    const [callObj, setCallObj] = useState(null);

    const [showContacts, setShowContacts] = useState(false);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [contactLists, setContactLists] = useState([]);
    const [currList, setCurrList] = useState(null);
    const [currContact, setCurrContact] = useState(null);
    const [playSound, setPlaySound] = useState(true);

    function onIdle() {
        setShowContacts(false);
    }

    function alertCall(data) {
        return sendAlert({
            id: data.call_id,
            type: 'call_request',
            body: {
                location: callObj ? callObj.location : null,
                zone: callObj ? callObj.zone : null,
                destination: data.destination,
            },
        });
    }

    async function notifyCall() {
        setAppState('calling');
        setCalling(true);
    }

    async function notifyCancel(type) {
        sendNotification({
            type,
            body: {
                alert_id: callObj ? callObj.call_id : '',
                location: callObj ? callObj.location : null,
                zone: callObj ? callObj.zone : null,
                destination: callObj.destination,
            },
        });
    }

    async function getContacts() {
        setCurrList(null);
        setLoadingContacts(true);
        await api
            .get(`apps/telepresence/contacts`, api_options)
            .then(response => {
                setContactLists(response.data);
            })
            .catch(error => {
                requestError(error);
            });
        setLoadingContacts(false);
        return true;
    }

    async function requestCall(contact_id = null) {
        setAppState('preparing');
        const call_body = {
            ...(contact_id && { contact_id }),
        };
        setCurrContact(null);
        setShowContacts(false);

        await api
            .post(`apps/telepresence/calls/request`, call_body, api_options)
            .then(response => {
                setCallObj(response.data);
                notifyCall();
            })
            .catch(error => {
                requestError(error);
                setCalling(false);
                setAppState('standby');
            });
    }

    function callAccepted() {
        setCalling(false);
        setAppState('connecting');
    }

    function callCancel() {
        setCalling(false);
        setCallObj(null);
        setAppState('standby');
        notifyCancel(NOTIFICATION_CALL_CANCELLED);
    }

    function callTimeout() {
        setCalling(false);
        setCallObj(null);
        setAppState('standby');
        notifyCancel(NOTIFICATION_CALL_TIMEOUT);
    }

    function callCenter() {
        requestCall();
    }

    function toggleContacts(open) {
        setShowContacts(open);
        if (open) {
            getContacts();
        }
        return true;
    }

    function handleNotification(notification) {
        const { type } = notification;
        switch (type) {
            case NOTIFICATION_CALL_ACCEPTED: {
                return callAccepted();
            }
            default:
                return false;
        }
    }

    function handleRequestCallAction(message) {
        const { contact } = message;
        if (
            telepresenceStatus &&
            !telepresenceStatus.inUse &&
            appState === 'standby'
        ) {
            requestCall(contact);
            clearActions();
        }
        return true;
    }

    function handleAction(message) {
        const { action_type } = message;
        switch (action_type) {
            case 'request_call':
                return handleRequestCallAction(message);
            default:
                return null;
        }
    }

    useEffect(() => {
        if (telepresenceStatus && telepresenceStatus.inUse) {
            if (appState === 'standby' || appState === 'connecting') {
                setAppState('in_use');
                setPlaySound(true);
            }
        } else if (appState === 'in_use') {
            setPlaySound(true);
            setAppState('standby');
        } else if (appState === 'connecting') {
            setTimeout(() => {
                setAppState('standby');
            }, connection_timeout);
        } else if (appState === 'timeout') {
            setTimeout(() => {
                setAppState('standby');
            }, standby_timeout);
        }
    }, [appState, status, telepresenceStatus]);

    useEffect(() => {
        if (lastNotification) {
            handleNotification(lastNotification);
        }
    }, [lastNotification]);

    useEffect(() => {
        if (lastAction) {
            handleAction(lastAction);
        }
    }, [lastAction]);

    useEffect(() => {
        if (calling && callObj) {
            alertCall(callObj);
            setTimeout(() => {
                callTimeout();
            }, call_timeout);
            const interval = setInterval(() => {
                alertCall(callObj);
            }, 5 * 1000);
            return () => clearInterval(interval);
        }
        return () => {};
    }, [calling, callObj]);

    const request_settings = {
        broadcast: {
            label: 'app.double_3.telepresence.call_center',
            onClick: () => {
                callCenter();
            },
        },
        contact: {
            label: 'app.double_3.telepresence.call_contact',
            onClick: () => {
                toggleContacts(true);
            },
        },
    };

    function renderContact(c) {
        const avatar = c.file && c.file.url ? c.file.url : null;
        return (
            <React.Fragment key={c.id}>
                {currContact && currContact === c.id ? (
                    <Dialog
                        open={currContact && currContact === c.id}
                        keepMounted
                        onClose={() => setCurrContact(null)}
                        PaperProps={{
                            style: {
                                width: '100%',
                                backgroundColor: '#00000000',
                                boxShadow: 'none',
                                color: '#fff',
                            },
                        }}
                    >
                        <ContactCard colors={colors}>
                            <Card style={{ height: '100%' }}>
                                <CardHeader
                                    title={c.name}
                                    subheader={c.description}
                                    titleTypographyProps={{ fontSize: '42px' }}
                                />
                                <CardContent>
                                    <Avatar
                                        alt={c.name}
                                        src={avatar}
                                        className="avatar"
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        onClick={() => setCurrContact(null)}
                                    >
                                        <MdClose size={28} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        onClick={() => requestCall(c.id)}
                                    >
                                        <MdCall size={28} />
                                    </Button>
                                </CardActions>
                            </Card>
                        </ContactCard>
                    </Dialog>
                ) : null}
                <ListItem
                    style={{ width: '100%' }}
                    onClick={() => setCurrContact(c.id)}
                >
                    <ListItemAvatar>
                        <Avatar alt={c.name} src={avatar} className="avatar" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={c.name}
                        secondary={c.description || ' '}
                    />
                </ListItem>
            </React.Fragment>
        );
    }

    function renderFilteredContacts() {
        const filtered_contacts = {};

        contactLists.forEach(l => {
            const { contacts } = l;
            if (currList && l.id !== currList) {
                return false;
            }
            contacts.forEach(c => {
                if (filtered_contacts[c.id]) {
                    return true;
                }
                filtered_contacts[c.id] = {
                    id: c.id,
                    name: c.name || '',
                    description: c.description || '',
                    file: c.file || null,
                };
            });
        });

        const contact_keys = Object.keys(filtered_contacts);
        return (
            <>
                {contact_keys.length > 0 ? (
                    <ContactList colors={colors}>
                        <Scrollbars
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <List>
                                {contact_keys.map(k => {
                                    return renderContact(filtered_contacts[k]);
                                })}
                            </List>
                        </Scrollbars>
                    </ContactList>
                ) : (
                    <LocaleMessage msg="app.double_3.telepresence.no_contacts" />
                )}
            </>
        );
    }

    function renderContactLists() {
        const lists = contactLists.map(l => {
            return {
                id: l.id,
                name: l.name,
            };
        });

        const hasMain = main && main.enabled;
        const mainContact = hasMain && main.contact ? main.contact : null;

        return (
            <Dialog
                open={showContacts}
                keepMounted
                onClose={() => toggleContacts(false)}
                fullWidth
                PaperProps={{
                    style: {
                        width: '100%',
                        height: '90vh',
                        backgroundColor: '#000',
                        boxShadow: 'none',
                        color: '#fff',
                    },
                }}
            >
                <DialogTitle>
                    <ContactHeader>
                        <img
                            className="pluginbot-logo"
                            src={logo}
                            alt="Pluginbot Logo"
                            title="Pluginbot Logo"
                        />
                    </ContactHeader>
                </DialogTitle>
                <DialogContent className="mb-3">
                    <ListsBody colors={colors} hasMain={hasMain}>
                        <Scrollbars
                            style={{
                                height: '10%',
                                width: '100%',
                            }}
                        >
                            <div className="lists">
                                {lists && lists.length > 1 ? (
                                    <Chip
                                        className="mx-1"
                                        label={
                                            <LocaleMessage msg="app.double_3.telepresence.all_contacts" />
                                        }
                                        onClick={() => setCurrList(null)}
                                        variant={
                                            !currList ? undefined : 'outlined'
                                        }
                                    />
                                ) : null}
                                {lists.map(l => {
                                    return (
                                        <Chip
                                            key={l.id}
                                            className="mx-1"
                                            label={l.name}
                                            variant={
                                                currList && currList === l.id
                                                    ? undefined
                                                    : 'outlined'
                                            }
                                            onClick={() => setCurrList(l.id)}
                                        />
                                    );
                                })}
                            </div>
                        </Scrollbars>
                        <div className="contacts col-12 row">
                            {loadingContacts ? (
                                <Splash />
                            ) : (
                                renderFilteredContacts()
                            )}
                        </div>
                        <div className="footer">
                            {mainContact ? (
                                <Button
                                    className="contact-button"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() =>
                                        requestCall(
                                            mainContact.id === '*'
                                                ? null
                                                : mainContact.id
                                        )
                                    }
                                    style={{
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    {mainContact.id === '*' ? (
                                        broadcast_text || (
                                            <LocaleMessage msg="app.double_3.telepresence.call_center" />
                                        )
                                    ) : (
                                        <div className="button-label">
                                            <small>
                                                <LocaleMessage msg="app.double_3.telepresence.call" />
                                            </small>
                                            {mainContact.name}
                                        </div>
                                    )}
                                </Button>
                            ) : null}
                            <IconButton
                                onClick={() => toggleContacts(false)}
                                style={{
                                    color: '#ddd',
                                }}
                            >
                                <MdClose size={30} />
                            </IconButton>
                        </div>
                    </ListsBody>
                </DialogContent>
            </Dialog>
        );
    }

    function renderStandby() {
        const request =
            allow_requests && request_mode && request_settings[request_mode]
                ? request_settings[request_mode]
                : null;
        return (
            <>
                {enable_sounds && playSound ? (
                    <Sound
                        url={hangout_sound}
                        loop={false}
                        playStatus={
                            playSound
                                ? Sound.status.PLAYING
                                : Sound.status.STOPPED
                        }
                        onFinishedPlaying={() => setPlaySound(false)}
                        debug={false}
                    />
                ) : null}
                {text ? (
                    <Title
                        size={text.size || 'medium'}
                        className="mb-3"
                        colors={colors}
                    >
                        <h1>{text.text}</h1>
                    </Title>
                ) : null}
                {image ? (
                    <ImgContainer>
                        <img
                            className="mb-1"
                            src={image.url}
                            alt={image.id}
                            width={image.width ? `${image.width}%` : '50%'}
                        />
                    </ImgContainer>
                ) : null}
                {request ? (
                    <Button
                        className="call-button"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                            request.onClick();
                        }}
                        disabled={!RC3Connected}
                    >
                        {((!request_mode || request_mode === 'broadcast') &&
                            broadcast_text) || (
                            <LocaleMessage msg={request.label} />
                        )}
                    </Button>
                ) : null}
            </>
        );
    }

    function renderCalling() {
        return (
            <>
                <Title className="mb-5" size="medium" colors={colors}>
                    <h1>
                        <LocaleMessage msg="app.double_3.telepresence.calling" />
                    </h1>
                </Title>

                <Call>
                    <IconButton
                        className="mb-5 pulse"
                        style={{
                            backgroundColor: '#f00',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            callCancel();
                        }}
                    >
                        <MdCallEnd size={size} />
                    </IconButton>
                </Call>
                {enable_sounds && appState === 'calling' ? (
                    <Sound
                        url={calling_sound}
                        loop
                        playStatus={Sound.status.PLAYING}
                        debug={false}
                    />
                ) : null}
            </>
        );
    }

    function renderMessage(m) {
        return (
            <>
                <Title className="mb-5" size="medium" colors={colors}>
                    <h1>{m}</h1>
                </Title>
            </>
        );
    }

    const content = {
        standby: renderStandby(),
        preparing: renderMessage(
            <LocaleMessage msg="app.double_3.telepresence.connecting" />
        ),
        calling: renderCalling(),
        connecting: renderMessage(
            <LocaleMessage msg="app.double_3.telepresence.connecting" />
        ),
        in_use: renderMessage(
            <LocaleMessage msg="app.double_3.telepresence.in_use" />
        ),
    };

    return (
        <Container colors={colors}>
            <IdleTimer
                element={document}
                onIdle={() => onIdle()}
                debounce={250}
                timeout={contact_timeout}
            />
            {enable_sounds && playSound && appState === 'in_use' ? (
                <Sound
                    url={joining_sound}
                    loop={false}
                    playStatus={
                        playSound ? Sound.status.PLAYING : Sound.status.STOPPED
                    }
                    onFinishedPlaying={() => setPlaySound(false)}
                    debug={false}
                />
            ) : null}
            {showContacts ? renderContactLists() : null}
            {content[appState] ? content[appState] : null}
        </Container>
    );
}

Telepresence.propTypes = {
    telepresenceStatus: PropTypes.object,
    settings: PropTypes.object,
    sendAlert: PropTypes.func.isRequired,
    lastNotification: PropTypes.object,
    sendNotification: PropTypes.func.isRequired,
    token: PropTypes.string,
    status: PropTypes.string,
    requestError: PropTypes.func.isRequired,
    lastAction: PropTypes.object,
    clearActions: PropTypes.func.isRequired,
    RC3Connected: PropTypes.bool,
};

Telepresence.defaultProps = {
    token: '',
    status: '',
    settings: {},
    lastNotification: {},
    lastAction: null,
    telepresenceStatus: {},
    RC3Connected: false,
};
