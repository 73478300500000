import { darken } from 'polished';
import styled from 'styled-components';

export const MainArea = styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: ${props => {
        const main_color = props.theme.palette.primary.main;
        const def_top_color = darken(0.1, main_color);
        const def_bottom_color = darken(0.3, main_color);
        const colors =
            props.visual && props.visual.colors ? props.visual.colors : {};
        return `linear-gradient(180deg, ${colors.top ||
            def_top_color}, ${colors.bottom || def_bottom_color})`;
    }};

    .portrait {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
`;

export const MapArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    height: 100%;
    width: 100%;
    border: 1px solid #ffffffcc;
    background-color: #000;

    img {
        max-height: 100%;
        max-width: 100%;
    }
`;

export const List = styled.div`
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ffffffcc;
`;

export const ListFilter = styled.div`
    width: 100%;
    color: #fff !important;

    span,
    label {
        color: #fff !important;
        font-family: 'Saira Semi Condensed', sans-serif !important;
    }

    span {
        .Mui-checked {
            color: #fff !important;
        }
    }
`;

export const RobotCard = styled.div`
    color: #fff;
    border: 1px solid #ffffffcc;
    width: 100%;
    margin: 0px;
    padding: 1vh 1vw;
    border-left: ${props => {
        const colors = {
            connected: '#0f0',
            occupied: '#ff0',
            alert: '#f00',
            disconnected: '#999',
        };

        const { status } = props;
        return `7px solid ${colors[status] || colors.disconnected}`;
    }};

    :hover {
        cursor: pointer;
    }

    .bold {
        font-weight: bold;
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    img {
        max-height: 100px;
        max-width: 100%;
    }
`;
