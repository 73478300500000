/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { MdClose, MdSend } from 'react-icons/md';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    TextField,
    Box,
    MenuItem,
    Select,
    TextareaAutosize,
} from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import nao_side from '~/assets/robots/nao/nao-side.png';
import AppLocale from '~/lngProvider';
import pluginbot_api from '~/services/pluginbot-api';
import {
    updateAppSettings,
    updateSessions,
} from '~/store/modules/sd02_indoor/actions';

import { App, Body, DarkDialog } from '../../styles';
import { Container, ChatDialog } from './styles';

const idle_timeout = 10 * 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const robot_img = {
    side: nao_side,
};

export default function NAOConversationContent({
    code,
    sendMessage,
    lastMessage,
    requestError,
    screen,
}) {
    const messagesEndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const [, setAppSettings] = useState(null);
    const [languages, setLanguages] = useState([]);

    const [currLanguage, setCurrLanguage] = useState('pt_BR');
    const [currMessage, setCurrMessage] = useState('');
    const [messageLog, setMessageLog] = useState([]);
    const [defMessages, setDefMessages] = useState([]);

    const [appVisual, setAppVisual] = useState({});

    const scrollToBottom = () => {
        return messagesEndRef && messagesEndRef.current
            ? messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
            : null;
    };

    useEffect(() => {
        scrollToBottom();
    }, [messageLog]);

    useEffect(() => {
        console.log({ lastMessage });
        if (lastMessage) {
            setMessageLog([...messageLog, lastMessage]);
        }
    }, [lastMessage]);

    const handleClose = () => {
        // setMessageDialog(null);
    };

    function sendUserMessage(m) {
        if (m === '') return;
        const data = {
            sender: 'user',
            language: currLanguage,
            text: m,
            timestamp: new Date(),
        };

        setMessageLog([...messageLog, data]);
        setCurrMessage('');
        sendMessage(data);
    }

    async function loadApplication(id) {
        await pluginbot_api
            .get(`/apps/indoor_delivery/${id}/visual`)
            .then(response => {
                const settings = response.data;
                // console.log(settings);
                setAppVisual(settings);
            })
            .catch(error => {
                requestError(error);
            });
    }

    function disconnectWebsocket() {
        // setServerURL(`ws://0.0.0.0:0`);
    }

    function updateAppSession(session) {
        // console.log(session);
        // const new_sessions = {
        //     ...u_sessions,
        //     [app_id]: session,
        // };
        // dispatch(updateSessions(new_sessions));
    }

    async function exitSession() {
        // const token = app_session && app_session.token ? app_session.token : '';
        const token = '';

        if (token) {
            const auth = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            await pluginbot_api
                .post(`/auth/signout`, {}, auth)
                .then(() => {})
                .catch(error => {
                    requestError(error);
                });
        }

        updateAppSession(null);
    }

    function renderMessage(m) {
        const timestamp = new Date(m.timestamp);
        const sender = m.sender || 'user';

        return (
            <div
                className={`chat-item d-flex flex-nowrap ${
                    sender === 'robot' ? '' : 'flex-row-reverse'
                } sender-${sender}`}
                key={`message_${timestamp.toISOString()}`}
            >
                <div className="bubble jambo-card">
                    <div className="message">{m.text}</div>
                    <div className="time text-muted text-right mt-2">
                        {timestamp.toLocaleTimeString(undefined)}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Container screen={screen}>
            <ChatDialog className="chat-box">
                <div className="chat-box-main">
                    <div className="chat-main-header">
                        <div className="chat-main-header-info">
                            <div className="chat-contact-name">
                                {code ? code.toUpperCase() : ''}
                            </div>
                            <div className="chat-contact-status">CONNECTED</div>
                        </div>
                    </div>

                    <div className="chat-main mx-0 px-0">
                        {screen && screen.isLandscape ? (
                            <div className="chat-side col-md-3 px-0">
                                <CustomScrollbars className="scrollbar">
                                    <div className="chat-side-content">
                                        <img
                                            src={robot_img.side}
                                            alt="robot_nao_side"
                                        />
                                    </div>
                                </CustomScrollbars>
                            </div>
                        ) : null}
                        <div
                            className={`col-md-${
                                screen && screen.isLandscape ? '9' : '12'
                            } px-0`}
                        >
                            <CustomScrollbars className="chat-list-scroll scrollbar">
                                <div className="chat-main-content">
                                    {messageLog.map(m => renderMessage(m))}
                                </div>
                                <div ref={messagesEndRef} />
                            </CustomScrollbars>
                        </div>
                    </div>
                    <div className="chat-main-footer">
                        <div
                            className="d-flex flex-row align-items-center"
                            style={{ height: '100%' }}
                        >
                            <div className="chat-lang">
                                <Select
                                    value={currLanguage}
                                    onChange={event =>
                                        setCurrLanguage(event.target.value)
                                    }
                                >
                                    {languages.map(l => {
                                        const l_split = l.split('_');
                                        const l_country = l_split[1]
                                            ? l_split[1].toLowerCase()
                                            : 'br';
                                        return (
                                            <MenuItem
                                                key={`lang_${l}`}
                                                value={l}
                                            >
                                                <IconButton className="icon-btn">
                                                    <i
                                                        className={`flag flag-24 flag-${l_country}`}
                                                    />
                                                </IconButton>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <TextareaAutosize
                                        className="border-0 form-control chat-textarea"
                                        value={currMessage}
                                        color="primary"
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                sendUserMessage(currMessage);
                                            }
                                        }}
                                        onChange={event =>
                                            setCurrMessage(event.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="chat-sent">
                                <IconButton
                                    onClick={() => sendUserMessage(currMessage)}
                                >
                                    <MdSend color="primary" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ChatDialog>
        </Container>
    );
}

NAOConversationContent.defaultProps = {
    screen: {},
    lastMessage: null,
    code: '',
};

NAOConversationContent.propTypes = {
    screen: PropTypes.object,
    requestError: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    lastMessage: PropTypes.object,
    code: PropTypes.string,
};
