import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import '~/assets/vendors/style';

import Pages from './pages';
import history from './services/history';
import { store, persistor } from './store';

export default function App() {
    const { location } = window;
    if (location.hash.startsWith('#/')) {
        history.push(location.hash.replace('#', ''));
    }
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router history={history}>
                    <Pages />
                </Router>
            </PersistGate>
        </Provider>
    );
}
