/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { MdClose, MdSettings } from 'react-icons/md';
import IdleTimer from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';

import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
    Slider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import NoRobot from '~/components/NoRobot';
import PWNumpad from '~/components/PWNumpad';

import {
    D3SessionStartMessage,
    D3SessionEndMessage,
    D3EndpointMessage,
    D3SysInfoMessage,
    D3HeadInfoMessage,
    D3VolumeMessage,
    D3BaseStatusMessage,
    D3BaseVersionMessage,
    D3NetworkInfoMessage,
    D3NetworkSignalMessage,
    D3NetworkLocationMessage,
    D3RemoteInfoMessage,
    D3UpdatingMessage,
} from '~/lib/Double3/handleMessages';
import {
    updateRobotID,
    updateRobotPassword,
} from '~/store/modules/robot/actions';

import Content from './Content';
import Footer from './Footer';
import iconSelector from './Footer/iconSelector';
import {
    Container,
    HiddenOptions,
    Body,
    SlidersContainer,
    Sliders,
} from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogText = withStyles(theme => ({
    root: {
        color: '#ddd',
    },
}))(DialogContentText);

const SIGNAL_LEVELS = ['weak', 'regular', 'good', 'excelent'];

const mb = '20px';
const HOME_APP = 'https://d3.doublerobotics.com';
const IDLE_TIMEOUT = 15 * 1000;
const D3_APP_VERSION = process.env.REACT_APP_TELEPRESENCE_APP_VERSION;

const d3_events = [
    'DREndpointModule.sessionBegin',
    'DREndpointModule.sessionEnd',
    'DREndpointModule.status',
    'DRAPI.status',
    'DRAPI.remoteConfiguration',
    'DRBase.status',
    'DRBase.version',
    'DRNetwork.info',
    'DRNetwork.signal',
    'DRNetwork.location',
    'DRSystem.systemInfo',
    'DRCalibration.values',
    'DRUpdater.installDebRemoteDownloadBegin',
    'DRSpeaker.volume',
];

export default function Double3() {
    const dispatch = useDispatch();
    const r_settings = useSelector(state => state.robot || null);
    const r_pw = r_settings.password;

    const { DRDoubleSDK } = window;

    function resetWatchdog() {
        if (DRDoubleSDK) {
            // console.log('Reseting Watchdog');
            DRDoubleSDK.resetWatchdog();
        }
    }

    const isD3Available = !!DRDoubleSDK;
    const isDev =
        process.env.REACT_APP_ENV_VERSION === 'local' ||
        process.env.REACT_APP_ENV_VERSION === 'development';

    const [currSetting, setCurrSetting] = useState(null);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [messageDialog, setMessageDialog] = useState(false);

    const [RC3Connected, setRC3Connected] = useState(false);

    const [robotStatus, setRobotStatus] = useState({});
    const [robotSettings, setRobotSettings] = useState(null);
    const [visualSettings, setVisualSettings] = useState({
        logo: null,
    });

    const [passwordOpen, setPasswordOpen] = useState(false);

    const [volSlider, setVolSlider] = useState(0);
    const [poleSlider, setPoleSlider] = useState(0);

    const stateRef = useRef().current || {};

    if (!isD3Available && !isDev) {
        console.error('ERROR: DRDoubleSDK not found');
    }

    resetWatchdog();

    function sendCommand(command, options = {}) {
        // console.log({ command, options });
        if (DRDoubleSDK) {
            return DRDoubleSDK.sendCommand(command, options);
        }
        return false;
    }

    function setCallFeatures() {
        const default_features = {
            allowDisablingObstacleAvoidance: true,
            defaultToObstacleAvoidanceStop: false,
            ignoreUltrasonic: false,
            allowMicDuringPoleMotion: false,
            minimumPerformanceModel: false,
            hideVisitorPassButton: false,
            defaultAudioBoostLevel: 0,
            enableFloorDepthClipping: false,
            playCallChimes: false,
            // defaultSpeakerVolume: 0.67,
            // skipRetractKickstand: false,
            enableTagDetector: true,
            disableTiltMinLimit: false,
            lowQualityOnly: false,
            disablePhoto: false,
            disableApp_multiviewer: false,
            disableApp_screensharing: false,
            disableApp_webpage: false,
            disableApp_text: false,
            disableApp_satellite: false,
        };

        const override_features = {
            enableTagDetector: false,
            allowDisablingObstacleAvoidance: false,
            // disablePhoto: true,
            // disableApp_multiviewer: true,
            // disableApp_screensharing: true,
            // disableApp_webpage: true,
            // disableApp_text: true,
            // disableApp_satellite: true,
        };

        const features_config = {
            ...default_features,
            ...override_features,
        };

        console.log(features_config);

        sendCommand('endpoint.setOptions', features_config);
    }

    useEffect(() => {
        sendCommand('gui.watchdog.disallow');
        dispatch(updateRobotID(null));
        dispatch(updateRobotPassword('441056'));
        setCallFeatures();
    }, []);

    function requestInfo() {
        sendCommand('endpoint.enable');
        sendCommand('endpoint.requestIdentity', { requestSetupLink: false });
        sendCommand('endpoint.requestModuleStatus');
        sendCommand('system.requestInfo');
        sendCommand('base.requestStatus');
        sendCommand('base.requestVersion');
        sendCommand('network.requestLocation');
        sendCommand('network.requestInfo');
        sendCommand('api.requestLocalConfiguration');
        sendCommand('api.requestRemoteConfiguration');
        sendCommand('speaker.requestVolume');
        sendCommand('calibration.requestValues');
    }

    function subscribeD3() {
        if (DRDoubleSDK) {
            if (DRDoubleSDK.isConnected()) {
                // Class.key of subscribed events
                DRDoubleSDK.sendCommand('events.subscribe', {
                    events: d3_events,
                });

                return true;
            }
        }
        return false;
    }

    function exitApp() {
        sendCommand('gui.go.standby', {
            url: HOME_APP,
        });
    }

    function uninstallApp() {
        sendCommand('api.setConfig', {
            key: 'STANDBY_URL',
            value: HOME_APP,
        });
        sendCommand('system.restartService');
    }

    // Stablishes the connection and re-call the function if need to reconnect
    function onConnect() {
        if (DRDoubleSDK) {
            subscribeD3();
            // Requests the Robot Infos
            requestInfo();
            return true;
        }

        // console.log('onConnected > timeout');
        return window.setTimeout(onConnect, 100);
    }

    function connectSDK() {
        if (DRDoubleSDK) {
            onConnect();
            // Try to connect to DRDoubleSDK and set auto reconnection
            DRDoubleSDK.on('disconnect', () => {
                onConnect();
            });
        } else if (isDev) {
            // Debug mode
            const r_ids = [2];
            setRobotSettings({
                identifier: `robot_test_${
                    r_ids[Math.floor(Math.random() * r_ids.length)]
                }`,
                address: '127.0.0.1',
            });
        }
    }

    // EFFECTS
    function saveStatus(key, values) {
        setRobotStatus(prevState => {
            const ref_obj = prevState && prevState[key] ? prevState[key] : {};
            const new_obj = { ...ref_obj, ...values };

            if (key === 'connection') {
                const up_value = values.level || 0;
                const old_value =
                    prevState && prevState.connection
                        ? prevState.connection
                        : {};
                if (up_value && up_value === old_value.level) {
                    return prevState;
                }
            }
            if (key === 'settings') {
                const up_value = values.base_serial || '---';
                const old_value =
                    prevState && prevState.settings ? prevState.settings : {};
                if (up_value && up_value === old_value.base_serial) {
                    return prevState;
                }
            }
            const new_state = { ...prevState, [key]: new_obj };
            if (key === 'settings') {
                setRobotSettings(new_obj);
            }
            return { ...new_state };
        });
    }

    // Robot Settings
    function handleSysInfoMessage(message) {
        const sys_info = D3SysInfoMessage(message);
        return saveStatus('settings', sys_info);
    }

    function handleHeadInfoMessage(message) {
        const head_info = D3HeadInfoMessage(message);
        return saveStatus('head', head_info);
    }

    function handleBaseVersionMessage(message) {
        const base_version = D3BaseVersionMessage(message);
        return saveStatus('settings', base_version);
    }

    function handleRemoteInfoMessage(message) {
        const remote_info = D3RemoteInfoMessage(message);
        return saveStatus('settings', remote_info);
    }

    function handleUpdaterMessage(message) {
        const updater = D3UpdatingMessage(message);
        return saveStatus('settings', updater);
    }

    // Software Status
    function handleSessionMessage(message, start) {
        const session_status = start
            ? D3SessionStartMessage(message)
            : D3SessionEndMessage(message);
        return saveStatus('software', session_status);
    }

    function handleEndpointMessage(message) {
        const endpoint_status = D3EndpointMessage(message);
        if (endpoint_status.robot_key) {
            saveStatus('settings', { robot_key: endpoint_status.robot_key });
        }
        return saveStatus('software', endpoint_status);
    }

    // Hardware Status
    function handleBaseStatusMessage(message) {
        const base_status = D3BaseStatusMessage(message);
        setPoleSlider(base_status.pole);
        return saveStatus('hardware', base_status);
    }

    function handleVolumeStatusMessage(message) {
        const volume = D3VolumeMessage(message);
        const volValue = volume * 100;
        setVolSlider(volValue);
        return saveStatus('volume', { level: volume });
    }

    // Connection Status
    function handleNetworkInfoMessage(message) {
        const network = D3NetworkInfoMessage(message);
        return saveStatus('connection', network);
    }

    function handleNetworkSignalMessage(message) {
        const signal = D3NetworkSignalMessage(message);
        return saveStatus('connection', signal);
    }

    function handleNetworkLocationMessage(message) {
        const location = D3NetworkLocationMessage(message);
        return saveStatus('location', location);
    }

    const handleMsg = {
        'DRAPI.remoteConfiguration': message =>
            handleRemoteInfoMessage(message),
        'DRSystem.systemInfo': message => handleSysInfoMessage(message),
        'DRCalibration.values': message => handleHeadInfoMessage(message),
        'DRBase.version': message => handleBaseVersionMessage(message),
        'DRBase.status': message => handleBaseStatusMessage(message),
        'DRNetwork.info': message => handleNetworkInfoMessage(message),
        'DRNetwork.signal': message => handleNetworkSignalMessage(message),
        'DRNetwork.location': message => handleNetworkLocationMessage(message),
        'DREndpointModule.sessionBegin': message =>
            handleSessionMessage(message, true),
        'DREndpointModule.sessionEnd': message =>
            handleSessionMessage(message, false),
        'DREndpointModule.status': message => handleEndpointMessage(message),
        'DRUpdater.installDebRemoteDownloadBegin': () =>
            handleUpdaterMessage(true),
        'DRSpeaker.volume': message => handleVolumeStatusMessage(message),
    };

    window.onload = () => {
        resetWatchdog();
        sendCommand('gui.watchdog.disallow');
        connectSDK();
        if (DRDoubleSDK) {
            // console.log('Is Double Robot');
            DRDoubleSDK.on('event', message => {
                const { class: m_class, key } = message;
                const m_key = `${m_class}.${key}`;
                const handleMessageFunction = handleMsg[m_key];
                if (handleMessageFunction) {
                    handleMessageFunction(message);
                }
            });
        }
        // window.setInterval(() => {
        //     resetWatchdog();
        // }, 1500);
    };

    function handleSetValuesAction(data) {
        const val_key = data && data.key ? data.key : '';

        switch (val_key) {
            case 'kickstand': {
                const value = data && data.value ? data.value : false;
                const command = value ? 'deploy' : 'retract';
                sendCommand(`base.kickstand.${command}`);
                break;
            }
            case 'volume': {
                const value = data && data.value ? data.value : 0;
                sendCommand('speaker.enable');
                sendCommand('speaker.setVolume', { percent: value });
                break;
            }
            case 'pole': {
                const value = data && data.value ? data.value : 0;
                setSettingsOpen(false);
                setCurrSetting('');
                sendCommand('base.pole.setTarget', { percent: value });
                break;
            }
            default:
                break;
        }
        return true;
    }

    function handleSwipeEnd(value, key) {
        const percent = (value || 0) / 100;

        if (key === 'volume') {
            sendCommand('speaker.enable');
            return sendCommand('speaker.setVolume', { percent });
        }
        if (key === 'pole') {
            setSettingsOpen(false);
            setCurrSetting('');
            return sendCommand('base.pole.setTarget', { percent });
        }
        return false;
    }

    const handleClickOpen = () => {
        setSettingsOpen(true);
    };

    const handleClose = () => {
        setSettingsOpen(false);
        setMessageDialog(null);
        setPasswordOpen(false);
    };

    function handleHiddenSettingsClick() {
        setPasswordOpen(true);
    }

    function toggleSettings(item) {
        setCurrSetting(item);
        // requestInfo();
        if (item) {
            return handleClickOpen();
        }
        return handleClose();
    }

    function renderPopUpMessage(open, message) {
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        width: '70%',
                        backgroundColor: '#111',
                        boxShadow: 'none',
                        color: '#fff',
                        paddingBottom: '10px',
                    },
                }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#fff !important',
                        fontSize: '2.5vh',
                    }}
                >
                    {message.title || null}
                    <IconButton
                        onClick={handleClose}
                        style={{
                            marginRight: '0px',
                            paddingRight: '0px',
                            color: '#ddd',
                        }}
                    >
                        <MdClose />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    className="mb-3"
                    style={{
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {message.body}
                </DialogContent>
            </Dialog>
        );
    }

    function verifyPassword(password) {
        setPasswordOpen(false);

        if (password && r_pw && password === r_pw) {
            return toggleSettings('secret');
        }

        return setMessageDialog({
            title: <LocaleMessage msg="messages.invalid_password" />,
            body: null,
        });
    }

    function togglePark() {
        const hardwareStatus = robotStatus.hardware || {};

        const kickstand = hardwareStatus.kickstand || 1;
        const current_kickstand = kickstand !== 1;

        const command = current_kickstand ? 'deploy' : 'retract';
        sendCommand(`base.kickstand.${command}`);
    }

    function renderSliderSettings() {
        const robot_pole = poleSlider || 0;
        const volume_status = volSlider || 0;

        return (
            <SlidersContainer>
                <Sliders>
                    <div className="mb-3">
                        <LocaleMessage msg="app.double_3.settings.info.volume.value" />
                    </div>
                    <Slider
                        className="mb-3"
                        value={volume_status}
                        onChange={(event, v) => setVolSlider(v)}
                        onChangeCommitted={(event, v) =>
                            handleSwipeEnd(v, 'volume')
                        }
                        orientation="vertical"
                    />
                </Sliders>
                <Sliders>
                    <div className="mb-3">
                        <LocaleMessage msg="app.double_3.settings.info.height.value" />
                    </div>
                    <Slider
                        className="mb-3"
                        value={robot_pole || 0}
                        onChangeCommitted={(event, v) =>
                            handleSwipeEnd(v, 'pole')
                        }
                        onChange={(event, v) => setPoleSlider(v)}
                        orientation="vertical"
                    />
                </Sliders>
            </SlidersContainer>
        );
    }

    function renderSecretSettings() {
        const settings = robotSettings || {};
        const {
            base_serial,
            head_serial,
            app_version,
            remote_app_version,
            remote_app_url,
            updating,
        } = settings;

        return (
            <>
                <Button
                    fullWidth
                    variant="contained"
                    style={{ marginBottom: mb }}
                    onClick={() => {
                        exitApp();
                    }}
                >
                    <LocaleMessage msg="messages.exit_app" />
                </Button>

                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.robot.head_serial" />
                    {head_serial}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.robot.head_serial" />
                    {base_serial}
                </DialogText>
                <Button
                    fullWidth
                    variant="contained"
                    style={{ marginBottom: mb }}
                    onClick={() => {
                        sendCommand('gui.go.wifi', {});
                    }}
                >
                    <LocaleMessage msg="app.double_3.settings.info.connection.change" />
                </Button>
                {app_version ? (
                    <>
                        <DialogText>
                            <LocaleMessage msg="app.double_3.settings.info.robot.d3_version" />
                            {app_version}
                        </DialogText>
                        {app_version === remote_app_version ? (
                            <DialogText>
                                <LocaleMessage msg="messages.up_to_date" />
                            </DialogText>
                        ) : (
                            <>
                                {updating ? (
                                    <DialogText>
                                        <LocaleMessage msg="messages.updating_to" />
                                        {remote_app_version}
                                    </DialogText>
                                ) : (
                                    <>
                                        {remote_app_url ? (
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                style={{ marginBottom: mb }}
                                                onClick={() => {
                                                    sendCommand(
                                                        'updater.deb.installRemote',
                                                        {
                                                            url: remote_app_url,
                                                        }
                                                    );
                                                }}
                                            >
                                                <LocaleMessage msg="messages.update_robot" />
                                                {' - '}
                                                {remote_app_version}
                                            </Button>
                                        ) : null}
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : null}
            </>
        );
    }

    function renderConnectionSettings() {
        const connectionStatus =
            robotStatus && robotStatus.connection ? robotStatus.connection : {};
        const { ssid, ip, level } = connectionStatus;

        const signal = SIGNAL_LEVELS[level];

        return (
            <>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.connection.code" />
                    {r_settings.ID || '---'}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.connection.rc3" />
                    <LocaleMessage
                        msg={`rc3.status.${
                            RC3Connected ? 'connected' : 'disconnected'
                        }`}
                    />
                </DialogText>
                {!RC3Connected ? (
                    <Button
                        fullWidth
                        variant="contained"
                        style={{ marginBottom: mb }}
                        onClick={() => window.location.reload()}
                    >
                        <LocaleMessage msg="app.double_3.settings.info.connection.rc3.reload" />
                    </Button>
                ) : null}
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.connection.ssid" />
                    {ssid || ''}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.connection.ip" />
                    {ip || ''}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.connection.signal" />
                    {signal ? (
                        <LocaleMessage
                            msg={`app.double_3.settings.info.connection.signal.${signal}`}
                        />
                    ) : null}
                </DialogText>
            </>
        );
    }

    function renderStatusSettings() {
        const hardwareStatus =
            robotStatus && robotStatus.hardware ? robotStatus.hardware : {};
        const headStatus =
            robotStatus && robotStatus.head ? robotStatus.head : {};
        const robot_battery =
            hardwareStatus && hardwareStatus.battery
                ? hardwareStatus.battery
                : {};
        const settings = robotSettings || {};
        const { base_serial, app_version } = settings;
        const { head_serial } = headStatus;

        return (
            <>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.battery.value" />
                    {`${robot_battery.level || '---'} %`}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.robot.head_serial" />
                    {`${head_serial || ''}`}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.robot.body_serial" />
                    {`${base_serial || ''}`}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.robot.d3_version" />
                    {`${app_version || ''}`}
                </DialogText>
                <DialogText>
                    <LocaleMessage msg="app.double_3.settings.info.robot.pluginbot_version" />
                    {`${D3_APP_VERSION || ''}`}
                </DialogText>
            </>
        );
    }

    function renderPasswordInput() {
        return <PWNumpad limit={6} verifyPassword={pw => verifyPassword(pw)} />;
    }

    const settingsContent = {
        connection: {
            title: (
                <LocaleMessage msg="app.double_3.settings.connection.title" />
            ),
            body: renderConnectionSettings(),
        },
        status: {
            title: <LocaleMessage msg="app.double_3.settings.status.title" />,
            body: renderStatusSettings(),
        },
        password: {
            title: (
                <LocaleMessage msg="app.double_3.settings.insert_password.title" />
            ),
            body: renderPasswordInput(),
        },
        sliders: {
            title: <LocaleMessage msg="app.double_3.settings.settings.title" />,
            body: renderSliderSettings(),
        },
        secret: {
            title: <LocaleMessage msg="app.double_3.settings.settings.title" />,
            body: renderSecretSettings(),
        },
    };

    const footer_items = [
        {
            key: 'double_3.wifi',
            icon: iconSelector.getWifiIcon(
                robotStatus && robotStatus.connection
                    ? robotStatus.connection
                    : {}
            ),
            value: 'wifi',
            label: 'Wifi',
            onClick: () => toggleSettings('connection'),
            alert: !RC3Connected,
        },
        {
            key: 'double_3.battery',
            icon: iconSelector.getBatteryIcon(
                robotStatus && robotStatus.hardware ? robotStatus.hardware : {}
            ),
            value: 'battery',
            label: 'Battery',
            onClick: () => toggleSettings('status'),
        },
        {
            key: 'double_3.sliders',
            icon: iconSelector.getSettingsIcon(),
            value: 'sliders',
            label: 'Sliders',
            onClick: () => toggleSettings('sliders'),
        },
        {
            key: 'double_3.park',
            icon: iconSelector.getParkIcon(
                robotStatus && robotStatus.hardware ? robotStatus.hardware : {}
            ),
            value: 'park',
            label: 'Park',
            onClick: () => togglePark(),
        },
    ];

    function renderSettingsDialog() {
        const settings = settingsContent[currSetting] || {};

        return renderPopUpMessage(settingsOpen, settings);
    }

    return (
        <Container visual={visualSettings}>
            {isD3Available || isDev ? (
                <>
                    <IdleTimer
                        element={document}
                        onIdle={() => {
                            setSettingsOpen(false);
                            setPasswordOpen(false);
                            setMessageDialog(null);
                        }}
                        debounce={250}
                        timeout={IDLE_TIMEOUT}
                    />
                    {passwordOpen
                        ? renderPopUpMessage(true, settingsContent.password)
                        : null}
                    {messageDialog
                        ? renderPopUpMessage(true, messageDialog)
                        : null}
                    {settingsOpen ? renderSettingsDialog() : null}
                    <HiddenOptions>
                        <MdSettings
                            size="2.5vh"
                            style={{ color: '#ffffff00' }}
                            onClick={() => handleHiddenSettingsClick()}
                        />
                    </HiddenOptions>
                    <Body>
                        <Content
                            settings={robotSettings}
                            robotStatus={robotStatus || {}}
                            visualSettings={visualSettings}
                            setVisualSettings={visual =>
                                setVisualSettings(visual)
                            }
                            sendCommand={(cmd, opt) => sendCommand(cmd, opt)}
                            setPopUp={message => setMessageDialog(message)}
                            notifyRC3Status={s => setRC3Connected(s)}
                            exitApp={() => exitApp()}
                            uninstallApp={() => uninstallApp()}
                            setValues={values => handleSetValuesAction(values)}
                        />
                    </Body>
                    <Footer items={footer_items} />
                </>
            ) : (
                <NoRobot />
            )}
        </Container>
    );
}
