/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Title, Container } from './styles';

export default function DefaultAlert({ robot, content, removeAlert }) {
    return (
        <Container onClick={() => removeAlert()}>
            <Title className="col-12 pt-3" fontSize="1.25vh" />
        </Container>
    );
}

DefaultAlert.propTypes = {
    robot: PropTypes.object,
    content: PropTypes.object,
    removeAlert: PropTypes.func.isRequired,
};

DefaultAlert.defaultProps = {
    robot: {},
    content: {},
};
