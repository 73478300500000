import React, { useState } from 'react';
import { MdBackspace, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import OtpInput from 'react-otp-input';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { Container, ButtonContainer, Row, RowButton, Spacer } from './styles';

export default function PWNumpad({ limit, verifyPassword }) {
    const init_pw = {
        visible: false,
        password: '',
    };
    const [pw, setPW] = useState(init_pw);
    const { visible, password } = pw;

    function numberClick(n) {
        if (password.length < limit) {
            const pass = `${password}${n}`;
            const pw_body = {
                ...pw,
                password: pass,
            };
            setPW(pw_body);
            if (pass.length === limit) {
                verifyPassword(pass);
            }
        }
    }

    function showPWClick() {
        setPW({
            ...pw,
            visible: !visible,
        });
    }

    function backClick() {
        const len = password.length;
        if (len > 0) {
            const pass = password.substring(0, len - 1);
            setPW({
                ...pw,
                password: pass,
            });
        }
    }

    function handleClick(b) {
        const { type, key } = b;
        switch (type) {
            case 'number':
                return numberClick(key);
            case 'show':
                return showPWClick();
            case 'backspace':
                return backClick();
            default:
                return false;
        }
    }

    const buttons = [
        [
            { key: '7', type: 'number', content: '7' },
            { key: '8', type: 'number', content: '8' },
            { key: '9', type: 'number', content: '9' },
        ],
        [
            { key: '4', type: 'number', content: '4' },
            { key: '5', type: 'number', content: '5' },
            { key: '6', type: 'number', content: '6' },
        ],
        [
            { key: '1', type: 'number', content: '1' },
            { key: '2', type: 'number', content: '2' },
            { key: '3', type: 'number', content: '3' },
        ],
        [
            {
                key: 'show_pw',
                type: 'show',
                content: visible ? <MdVisibilityOff /> : <MdVisibility />,
            },
            { key: '0', type: 'number', content: '0' },
            {
                key: 'backspace',
                type: 'backspace',
                content: <MdBackspace />,
            },
        ],
    ];

    function renderButton(b) {
        return (
            <RowButton
                className="col-4"
                key={b.key}
                onClick={() => handleClick(b)}
            >
                {b.content}
            </RowButton>
        );
    }

    function renderButtonRow(row, idx) {
        return (
            <Row className="row col-12" key={`row_${idx}`}>
                {row.map(b => {
                    return renderButton(b);
                })}
            </Row>
        );
    }

    return (
        <Container>
            <OtpInput
                value={password}
                numInputs={limit}
                separator={<Spacer />}
                isInputSecure={!visible}
                inputStyle={{
                    fontFamily: 'Saira Semi Condensed, sans-serif',
                    fontSize: '3.5vh',
                }}
            />
            <ButtonContainer>
                {buttons.map((r, i) => {
                    return renderButtonRow(r, i);
                })}
            </ButtonContainer>
            <Button
                className="enter-button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                    verifyPassword(password);
                }}
            >
                <LocaleMessage msg="messages.enter" />
            </Button>
        </Container>
    );
}

PWNumpad.propTypes = {
    limit: PropTypes.number,
    verifyPassword: PropTypes.func.isRequired,
};

PWNumpad.defaultProps = {
    limit: 6,
};
