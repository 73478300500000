/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import { UserMenu } from '../styles';

export default function UserInfoPopup({
    profile,
    onLogoutClick,
    showStatusProfilePic,
}) {
    const { name, avatar } = profile;

    return (
        <UserMenu>
            <div className="user-profile">
                {showStatusProfilePic && avatar && avatar.url ? (
                    <img
                        className="user-avatar border-0 size-40 rounded-circle"
                        src={avatar ? avatar.url : null}
                        alt="User Avatar"
                    />
                ) : (
                    <div className="MuiAvatar-root bg-secondary size-30 text-white MuiAvatar-colorDefault rounded-circle">
                        {profile && profile.name
                            ? profile.name[0].toUpperCase()
                            : 'P'}
                    </div>
                )}
                <div className="user-detail ml-2">
                    <h4 className="user-name mb-0">{name}</h4>
                </div>
            </div>
            <span
                className="jr-link dropdown-item text-muted"
                onClick={() => {
                    onLogoutClick();
                }}
            >
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
                <LocaleMessage msg="messages.logout" />
            </span>
        </UserMenu>
    );
}

UserInfoPopup.propTypes = {
    onLogoutClick: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    onCloseMenu: PropTypes.func,
    showStatusProfilePic: PropTypes.bool,
};

UserInfoPopup.defaultProps = {
    onCloseMenu: () => {},
    showStatusProfilePic: false,
};
