import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

export default function SplashInside() {
    return (
        <Container>
            <ReactLoading type="bars" color="#fff" height={80} width={80} />
        </Container>
    );
}
