// import { Sara, Client as SaraClient } from '~/lib/Synkar/sara-sdk';

// const access_key = process.env.REACT_APP_PB_SYNKAR_ACCESS;
// const secret_key = process.env.REACT_APP_PB_SYNKAR_SECRET;

export async function syncMap(robot_key, map_name) {
    // console.log('Syncing...');
    // console.log(`Robot: ${robot_key}`);
    // console.log(`Target Map: ${map_name}`);
    // try {
    //     await SaraClient.auth(access_key, secret_key);
    //     const mapping = new Sara.Mapping(robot_key);
    //     await mapping.image(
    //         () => {
    //             console.log('Mapping SDK - Initialized successfully');
    //             return {
    //                 success: true,
    //                 message: 'Mapping SDK - Initialized successfully',
    //             };
    //         },
    //         () => {
    //             console.log('Mapping SDK - Failed to Initialize');
    //             return {
    //                 success: false,
    //                 message: 'Mapping SDK - Failed to Initialize',
    //             };
    //         }
    //     );
    //     const swap_result = await mapping
    //         .swap(map_name)
    //         .then(response => {
    //             console.log('Finished swap command', response);
    //             return {
    //                 success: true,
    //                 message: 'Finished swap command',
    //             };
    //         })
    //         .catch(error => {
    //             console.log('Error on swap mapping', error);
    //             return {
    //                 success: false,
    //                 message: 'Error on swap mapping',
    //                 error,
    //             };
    //         });
    //     return swap_result;
    // } catch (err) {
    //     return {
    //         success: false,
    //         message: 'Error on Mapping SDK',
    //         error: err,
    //     };
    // }
}
