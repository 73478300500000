/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import {
    MdBattery20,
    MdBattery30,
    MdBattery50,
    MdBattery60,
    MdBattery80,
    MdBattery90,
    MdBatteryFull,
    MdBatteryChargingFull,
    MdBatteryAlert,
    MdSignalWifi1Bar,
    MdSignalWifi2Bar,
    MdSignalWifi3Bar,
    MdSignalWifi4Bar,
    MdSignalWifiOff,
    MdTune,
} from 'react-icons/md';
import { RiParkingBoxFill, RiParkingBoxLine } from 'react-icons/ri';

class IconSelector {
    getBatteryIcon(hardwareStatus = {}) {
        const battery = hardwareStatus.battery || {};

        const { level, charging } = battery;

        if (charging) {
            return MdBatteryChargingFull;
        }
        if (level >= 95) {
            return MdBatteryFull;
        }
        if (level >= 85 && level < 95) {
            return MdBattery90;
        }
        if (level >= 75 && level < 85) {
            return MdBattery80;
        }
        if (level >= 55 && level < 75) {
            return MdBattery60;
        }
        if (level >= 45 && level < 55) {
            return MdBattery50;
        }
        if (level >= 25 && level < 45) {
            return MdBattery30;
        }
        if (level >= 15 && level < 25) {
            return MdBattery20;
        }

        return MdBatteryAlert;
    }

    getWifiIcon(connectionStatus = {}) {
        const { level, ssid } = connectionStatus;

        if (!ssid) {
            return MdSignalWifiOff;
        }

        switch (level) {
            case 3:
                return MdSignalWifi4Bar;
            case 2:
                return MdSignalWifi3Bar;
            case 1:
                return MdSignalWifi2Bar;
            case 0:
                return MdSignalWifi1Bar;
            default:
                return MdSignalWifiOff;
        }
    }

    getSettingsIcon() {
        return MdTune;
    }

    getParkIcon(hardwareStatus = {}) {
        const kickstand = hardwareStatus.kickstand || 1;

        if (kickstand === 1) {
            return RiParkingBoxFill;
        }
        if (kickstand === 2) {
            return RiParkingBoxLine;
        }

        return kickstand % 2 === 0 ? RiParkingBoxLine : RiParkingBoxLine;
    }
}

export default new IconSelector();
