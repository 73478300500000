import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
    0% {box-shadow: 0 0 0 0 #99999999; }
    50% {box-shadow: 0 0 0 100px #66666666;}
    100% {box-shadow: 0 0 0 0 #44444444; }
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 45vh;
    max-height: 70vh;
    color: ${props => {
        const colors = props.colors ? props.colors : {};
        const { text: text_color } = colors;
        return text_color || '#ffffff';
    }} !important;

    .call-button {
        margin: 3vh 1vw;
        font-size: 3vh;
        font-family: 'Saira Semi Condensed', sans-serif !important;
        background-color: ${props => {
            const colors = props.colors ? props.colors : {};
            const { call_button } = colors;
            return call_button || '#000';
        }};
    }
`;

export const ImgContainer = styled.div`
    width: 80%;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
`;

export const Title = styled.div`
    max-width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    color: ${props => {
        const colors = props.colors ? props.colors : {};
        const { text: text_color } = colors;
        return text_color || '#ffffff';
    }} !important;

    h1 {
        font-weight: bold;
        font-size: ${props => {
            const s = props.size || 'medium';
            const sizes = {
                small: '3vh',
                medium: '5vh',
                large: '7vh',
            };
            return sizes[s];
        }};
        color: ${props => {
            const colors = props.colors ? props.colors : {};
            const { text: text_color } = colors;
            return text_color || '#ffffff';
        }} !important;
    }
`;

export const Call = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .pulse {
        margin: 7vh 1vw;
        animation-name: ${pulse};
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
`;

export const ContactHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 5%;
        max-width: 25%;

        &.pluginbot-logo {
            max-width: 25%;
        }
    }
`;

export const ListsBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .lists {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0px 10px;

        .MuiChip-root {
            font-size: 1.75vh !important;
            padding-left: 15px;
            padding-right: 15px;
        }

        .MuiChip-clickable {
            color: #000 !important;
            background-color: #fff !important;
        }

        .MuiChip-outlined {
            border: 1px solid #fff;
            color: #fff !important;
            background-color: #00000000 !important;
        }
    }

    .contacts {
        width: 100%;
        min-height: ${props => {
            return props.hasMain ? '70%' : '85%';
        }};
        max-height: ${props => {
            return props.hasMain ? '70%' : '85%';
        }};
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        font-size: 2vh;
    }

    .footer {
        width: 100%;
        height: ${props => {
            return props.hasMain ? '20%' : '5%';
        }};
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0px 10px;

        .contact-button {
            font-size: 2.25vh;
            font-family: 'Saira Semi Condensed', sans-serif !important;
            background-color: ${props => {
                const colors = props.colors ? props.colors : {};
                const { call_button } = colors;
                return call_button || '#000';
            }};

            .button-label {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }
        }
    }
`;

export const ContactList = styled.div`
    width: 100%;
    height: 100%;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    font-size: 2.25vh;

    .MuiListItemText-root {
        margin-left: 2.5vw;

        span {
            font-size: 2.25vh !important;
        }
    }

    .MuiTypography-colorTextSecondary {
        color: #ddd;
        font-size: 1.75vh;
    }

    .MuiAvatar-root {
        width: 6vh;
        height: 6vh;
        max-width: 6vh;
        max-height: 6vh;
        border: 2px solid #fff;
    }

    .MuiAvatar-colorDefault {
        background-color: ${props => {
            const colors = props.colors ? props.colors : {};
            const { call_button } = colors;
            return call_button || '#000';
        }};
    }

    .MuiList-root {
        border: 1px solid #999;
        padding: 0px;
    }

    .MuiListItem-root {
        padding-left: 20px;
        padding-right: 20px;
        :not(:last-child) {
            border-bottom: 1px solid #999;
        }
    }
`;

export const ContactCard = styled.div`
    width: 100%;
    height: 100%;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MuiCard-root {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: #000;
        border: 1px solid #ccc;

        .MuiAvatar-root {
            margin: 1vh;
            height: 15vh;
            width: 15vh;
        }

        .MuiAvatar-colorDefault {
            background-color: ${props => {
                const colors = props.colors ? props.colors : {};
                const { call_button } = colors;
                return call_button || '#000';
            }};
        }

        .MuiCardActions-root {
            padding: 0px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .MuiButton-root {
                width: 40%;
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }

    .MuiCardHeader-root {
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .MuiCardHeader-content {
            color: #fff !important;
        }

        .MuiCardHeader-title {
            font-size: 3vh;
        }

        .MuiTypography-colorTextSecondary {
            color: #ccc !important;
        }

        span {
            text-align: center;
        }
    }

    .card-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .MuiAvatar-root {
            height: 10vh;
            width: 10vh;
        }

        .MuiAvatar-colorDefault {
            background-color: ${props => {
                const colors = props.colors ? props.colors : {};
                const { call_button } = colors;
                return call_button || '#000';
            }};
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;

        .card-description {
            font-size: 1.35vh;
        }
    }
`;
