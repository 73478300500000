/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { VscWarning } from 'react-icons/vsc';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import AlertExtras from '../Extras';
import { Title, Container } from '../styles';

export default function HelpRequest({
    robot,
    content,
    removeAlert,
    setCurrRobot,
}) {
    function onAlertClick() {
        setCurrRobot(robot);
        removeAlert();
    }

    const { map_layer, map_point } = content;
    const delivery_destination = `${map_layer ? map_layer.name : ''} ${
        map_layer && map_point && map_point.name ? ` - ${map_point.name}` : ''
    }`;

    const extras = [
        {
            key: 'destination',
            value: delivery_destination,
        },
    ];

    return (
        <Container
            onClick={() => {
                onAlertClick();
            }}
        >
            <Title className="col-12 p-1">
                <strong>
                    <LocaleMessage msg="app.indoor_delivery.alert.help_request" />
                </strong>
            </Title>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ padding: '0px 10px' }}>
                    <VscWarning
                        style={{
                            fontSize: '5vh',
                        }}
                    />
                </div>
                <div className="robot-identification">
                    <span className="name">
                        [{robot.code}] {robot.name}
                    </span>
                </div>
                <div style={{ padding: '0px 10px' }}>
                    <VscWarning
                        style={{
                            fontSize: '5vh',
                        }}
                    />
                </div>
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}>
                <AlertExtras infos={extras} />
            </div>
        </Container>
    );
}

HelpRequest.propTypes = {
    robot: PropTypes.object,
    content: PropTypes.object,
    removeAlert: PropTypes.func.isRequired,
    setCurrRobot: PropTypes.func.isRequired,
};

HelpRequest.defaultProps = {
    robot: {},
    content: {},
};
