import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    height: 100vh;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(180deg, ${color}, ${darken(0.25, color)})`;
    }};
`;

export const Body = styled.div`
    padding: 0px;
    max-width: 100vw;
    margin: auto;
    min-height: 100vh;
`;

export const NullDiv = styled.div``;
