import { shade, lighten } from 'polished';
import styled from 'styled-components';

export const MapArea = styled.div`
    /* background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(90deg, ${shade(0.9, color)}, ${shade(
            0.85,
            color
        )})`;
    }}; */

    height: ${props => props.height || '70vh'};
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    svg {
        :hover {
            cursor: ${props => props.handler};
        }
    }

    g {
        line,
        circle {
            fill: white;
            :hover {
                cursor: ${props => props.handler};
                fill: blue;
            }
        }

        .active {
            fill: ${props => {
                const color = props.theme.palette.primary.main;
                return `${lighten(0.35, color)}`;
            }};
        }
    }

    :hover {
        cursor: ${props => props.handler};
    }

    .react-transform-wrapper {
        width: 100%;
        height: 100%;
        background-color: #0f0;

        :hover {
            cursor: 'move';
        }
    }

    rect {
        /* fill: #CDCDCD */
    }

    [role="toolbar"]{
        display: none;
    }
`;
