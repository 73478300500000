/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    TextField,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import AppLocale from '~/lngProvider';
import pluginbot_api from '~/services/pluginbot-api';
import {
    updateAppSettings,
    updateSessions,
} from '~/store/modules/sd02_indoor/actions';

import { App, Body, DarkDialog } from '../styles';
import Connection from './Connection';
import Content from './Content';

const idle_timeout = 10 * 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NAOConversation({ match }) {
    const { robot_code } = match.params;

    const dispatch = useDispatch();
    const app_state = useSelector(state => state.nao_conversation || {});
    const sessions = app_state.sessions || {};
    // const app_session = sessions[robot_code];
    const app_session = {};

    const [serverURL, setServerURL] = useState('127.0.0.1');
    const [connectionSettings, setConnectionSettings] = useState({});

    const [screen, setScreen] = useState(null);
    const [appVisual, setAppVisual] = useState({});
    const [lastMessage, setLastMessage] = useState({});

    const [popupDialog, setPopupDialog] = useState(false);

    const isLandscape = screen && screen.orientation === 'landscape';
    const isBigScreen = screen && screen.width >= 1024;

    function handleWSConnected() {
        console.log('opened');
    }

    const { sendJsonMessage, readyState } = useWebSocket(serverURL, {
        onOpen: () => handleWSConnected(),
        onClose: () => console.log('closed'),
        onError: () => console.log('error'),
        shouldReconnect: closeEvent => true,
        onMessage: m => {
            const msg = JSON.parse(m.data || '{}');
            setLastMessage(msg);
        },
    });
    console.log(readyState);

    const getScreenProps = () => {
        const props = {
            ...screen,
        };
        if (window.matchMedia('(orientation: portrait)').matches) {
            props.orientation = 'portrait';
        }

        if (window.matchMedia('(orientation: landscape)').matches) {
            props.orientation = 'landscape';
        }

        const windowW = window.innerWidth;
        props.width = windowW;
        setScreen(props);
    };

    useEffect(() => {
        getScreenProps();
        window.addEventListener('resize', getScreenProps);
    }, []);

    function connectWebSocket(c) {
        setConnectionSettings(c);
        const url = `ws://${c.ip || '0'}:4200`;
        setServerURL(url);
        setLastMessage(null);
    }

    const handleClose = () => {
        setPopupDialog(null);
    };

    function requestError(error) {
        if (error.response) {
            const error_msg = (
                <span style={{ color: '#fff !important' }}>
                    {error.response.data.error}
                </span>
            );

            return setPopupDialog({
                title: <LocaleMessage msg="errors.generic" />,
                body: error_msg,
            });
        }
        return setPopupDialog({
            title: <LocaleMessage msg="errors.generic" />,
            body: '',
        });
    }

    async function loadApplication(id) {
        await pluginbot_api
            .get(`/apps/indoor_delivery/${id}/visual`)
            .then(response => {
                const settings = response.data;
                // console.log(settings);
                setAppVisual(settings);
                dispatch(
                    updateAppSettings({
                        ...app_state.settings,
                        [id]: settings,
                    })
                );
            })
            .catch(error => {
                requestError(error);
            });
    }

    useEffect(() => {
        // loadApplication(app_id);
    }, [robot_code]);

    function renderPopUpMessage(open, m) {
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        width: '70%',
                        backgroundColor: '#111',
                        boxShadow: 'none',
                        paddingBottom: '10px',
                        color: '#fff !important',
                    },
                }}
            >
                <DarkDialog>
                    <DialogTitle disableTypography>
                        {m.title || null}
                        <IconButton
                            onClick={handleClose}
                            style={{
                                marginRight: '0px',
                                paddingRight: '0px',
                                color: '#ddd',
                            }}
                        >
                            <MdClose />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="mb-3">{m.body}</DialogContent>
                </DarkDialog>
            </Dialog>
        );
    }

    function updateAppSession(session) {
        // console.log(session);
        const new_sessions = {
            ...sessions,
            [robot_code]: session,
        };
        dispatch(updateSessions(new_sessions));
    }

    async function exitSession() {
        const token = app_session && app_session.token ? app_session.token : '';

        if (token) {
            const auth = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            await pluginbot_api
                .post(`/auth/signout`, {}, auth)
                .then(() => {})
                .catch(error => {
                    requestError(error);
                });
        }

        updateAppSession(null);
    }

    return (
        <App visual={appVisual}>
            <>
                <IdleTimer
                    element={document}
                    onIdle={() => {
                        setPopupDialog(null);
                    }}
                    debounce={250}
                    timeout={idle_timeout}
                />
                {popupDialog ? renderPopUpMessage(true, popupDialog) : null}
                <Body>
                    {readyState !== 1 ? (
                        <Connection
                            visual={appVisual}
                            requestError={e => requestError(e)}
                            updateAppSession={s => updateAppSession(s)}
                            screen={{ isLandscape, isBigScreen }}
                            onConnectClick={c => connectWebSocket(c)}
                        />
                    ) : (
                        <Content
                            code={robot_code}
                            visual={appVisual}
                            session={app_session}
                            exitSession={() => exitSession()}
                            screen={{ isLandscape, isBigScreen }}
                            setPopUp={m => setPopupDialog(m)}
                            sendMessage={msg => sendJsonMessage(msg)}
                            lastMessage={lastMessage}
                        />
                    )}
                </Body>
            </>
        </App>
    );
}

NAOConversation.propTypes = {
    match: PropTypes.object.isRequired,
};
