import styled from 'styled-components';

export const PageSplash = styled.div`
    display: flex;
    padding: 0px;
    margin: 0px;
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100vh;
    z-index: 3000;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
