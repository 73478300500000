/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import PasswordInput from '~/components/PasswordInput';

import pluginbot_logo from '~/assets/logo-dark.png';
import api from '~/services/pluginbot-api';

import { ConnectionPage, ImgArea, LoginForm } from './styles';

export default function NAOConversationConnection({
    visual,
    updateAppSession,
    requestError,
    screen,
    onConnectClick,
}) {
    const pluginspaceSettings = visual.pluginspace || {};
    const pageSettings = visual.login_page || {};

    const [robotIP, setRobotIP] = useState('127.0.0.1');
    const [password, setPassword] = useState('');

    async function handleSubmit(event) {
        event.preventDefault();

        const body = {
            ip: robotIP,
            password,
        };
        onConnectClick(body);
    }

    return (
        <ConnectionPage className="row" screen={screen} visual={visual}>
            <ImgArea
                className={`${
                    screen.isLandscape || screen.isBigScreen ? 'col-md-7' : ''
                } col-12 min-50`}
                screen={screen}
                settings={pageSettings}
            >
                {pageSettings.show_image ? (
                    <img
                        className="extra-img mb-3"
                        src={
                            pageSettings.media && pageSettings.media.url
                                ? pageSettings.media.url
                                : pluginbot_logo
                        }
                        alt="Extra"
                        title="Extra"
                    />
                ) : null}
            </ImgArea>
            <LoginForm
                screen={screen}
                className={`${
                    screen.isLandscape || screen.isBigScreen ? 'col-md-5' : ''
                } col-12 p-md-4 p-7`}
            >
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <img
                        className="pluginbot-logo mb-3"
                        src={pluginbot_logo}
                        alt="Pluginbot Logo"
                        title="Pluginbot Logo"
                    />
                    {pageSettings.show_logo ? (
                        <img
                            className="pluginspace-logo mb-3"
                            src={
                                pluginspaceSettings.logo &&
                                pluginspaceSettings.logo.url
                                    ? pluginspaceSettings.logo.url
                                    : pluginbot_logo
                            }
                            alt="Pluginspace Logo"
                            title="Pluginspace Logo"
                        />
                    ) : null}
                </div>
                <div className="inputs">
                    <form>
                        <TextField
                            label="Robot IP"
                            fullWidth
                            value={robotIP}
                            onChange={event => setRobotIP(event.target.value)}
                            className="mb-3"
                            variant="outlined"
                        />
                        <PasswordInput
                            label={<LocaleMessage msg="labels.password" />}
                            fullWidth
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            className="mb-5"
                        />
                        <Button
                            margin="normal"
                            className="p-3"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={e => handleSubmit(e)}
                        >
                            Connect
                        </Button>
                    </form>
                </div>
            </LoginForm>
        </ConnectionPage>
    );
}

NAOConversationConnection.defaultProps = {
    visual: {},
};

NAOConversationConnection.propTypes = {
    screen: PropTypes.object.isRequired,
    visual: PropTypes.object,
    updateAppSession: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
    onConnectClick: PropTypes.func.isRequired,
};
