import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: ${props => {
        const def_top_color = props.theme.palette.primary.main;
        const def_bottom_color = darken(0.25, def_top_color);
        const colors =
            props.visual && props.visual.colors ? props.visual.colors : {};
        return `linear-gradient(180deg, ${colors.top ||
            def_top_color}, ${colors.bottom || def_bottom_color})`;
    }};
`;

export const HiddenOptions = styled.div`
    width: -webkit-fill-available;
    height: 2vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0.5vh 1vw;
`;

export const Title = styled.div`
    max-width: 80%;
    width: 80%;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 7vh;
        color: #fff;
    }

    h2 {
        font-size: 3.5vh;
        font-weight: bold;
        color: #fff;
    }

    h3 {
        font-size: 2.5vh;
        font-weight: bold;
        color: #fff;
    }
`;

export const LogoArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10vh;
    max-height: 10vh;

    img {
        max-width: 80%;
        max-height: 10vh;

        &.pluginbot-logo {
            max-width: 60%;
            width: ${props => {
                const ps_logo = props.hasLogo;
                return ps_logo ? '20%' : 'auto';
            }};
        }

        &.pluginpace-logo {
            height: 10vh;
            max-width: 80%;
        }
    }
`;

export const Body = styled.div`
    width: 100%;
    padding: 0px;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
`;

export const Footer = styled.div`
    width: 100%;
    height: 13vh;
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return darken(0.5, color);
    }};
    opacity: 0.75;

    .list-inline {
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        height: 100%;

        li {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.4vh;
        }
    }
`;

export const SlidersContainer = styled.div`
    width: 100%;
    padding: 20px;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
`;

export const Sliders = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    font-size: 2vh;

    .MuiSlider-root.MuiSlider-vertical {
        width: 20px;
    }

    .MuiSlider-vertical {
        .MuiSlider-rail {
            width: 20px;
            color: #ffffffdd;
        }
        .MuiSlider-track {
            width: 20px;
        }
        .MuiSlider-thumb {
            display: none;
        }
    }
`;

export const NullDiv = styled.div``;
