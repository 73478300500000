const urls = {
    local: 'https://4a84-2804-7f0-bf01-8011-74f5-c747-6ad5-31b0.ngrok.io',
    development: 'https://app-dev.pluginbot.ai',
    staging: 'https://app-staging.pluginbot.ai',
    production: 'https://app.pluginbot.ai',
};

export function getUrl() {
    const env_type = process.env.REACT_APP_ENV_VERSION || 'local';
    return urls[env_type] || urls.local;
}
