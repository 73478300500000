/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
    ID: null,
    status: {},
    password: '441056',
};

export default function robot(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@robot/UPDATE_ID': {
                const { ID } = action.payload;
                draft.ID = ID;
                break;
            }
            case '@robot/UPDATE_STATUS': {
                const { status } = action.payload;
                draft.status = status;
                break;
            }
            case '@robot/UPDATE_PASSWORD': {
                const { password } = action.payload;
                draft.password = password;
                break;
            }
            default:
                return state;
        }
    });
}
