import React from 'react';

import LocaleMessage from '~/components/LocaleMessage';

import logo from '~/assets/logo-white.png';

import { Body, Title } from './styles';

export default function NoRobot() {
    return (
        <Body>
            <img src={logo} alt="Pluginbot Logo" title="Pluginbot Logo" />
            <Title>
                <h2>
                    <LocaleMessage msg="messages.robot_not_identified" />
                </h2>
            </Title>
        </Body>
    );
}
