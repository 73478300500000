/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { MdHelp } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Badge } from '@material-ui/core';

import { Footer } from '../styles';

export default function Double3Footer({ items }) {
    function menuItem(item) {
        const Icon = item.icon || MdHelp;
        const { alert } = item;

        return (
            <li
                key={item.key}
                className="list-inline-item"
                onClick={item.onClick ? item.onClick : () => {}}
            >
                <Badge color="secondary" badgeContent=" " invisible={!alert}>
                    <Icon style={{ fontSize: '7vh' }} />
                </Badge>
            </li>
        );
    }

    return (
        <Footer>
            <ul className="list-inline">
                {items.map(i => {
                    return menuItem(i);
                })}
            </ul>
        </Footer>
    );
}

Double3Footer.propTypes = {
    items: PropTypes.array.isRequired,
};

Double3Footer.defaultProps = {};
