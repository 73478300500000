import { combineReducers } from 'redux';

import auth from './auth/reducer';
import robot from './robot/reducer';
import sd02_indoor from './sd02_indoor/reducer';
import settings from './settings/reducer';
import telepresence_app from './telepresence_app/reducer';

export default combineReducers({
    auth,
    robot,
    settings,
    telepresence_app,
    sd02_indoor,
});
