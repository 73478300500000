import React from 'react';

import Routes from '../routes';
import GlobalStyle from '../styles/global';

export default function Pages() {
    return (
        <>
            <Routes />
            <GlobalStyle />
        </>
    );
}
