import { darken } from 'polished';
import styled from 'styled-components';

export const App = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: ${props => {
        const def_color = '#0f0f0f';
        const colors =
            props.visual && props.visual.colors ? props.visual.colors : {};
        // const top_color = darken(0.25, colors.top || def_color);
        // const bottom_color = darken(0.25, colors.bottom || def_color);
        return `linear-gradient(180deg, ${colors.top ||
            def_color}, ${colors.bottom || def_color})`;
    }};
    span {
        font-family: 'Saira Semi Condensed', sans-serif !important;
    }

    .MuiAppBar-root {
        background-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return c;
        }};
    }

    .MuiButton-root {
        background-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return c;
        }};
    }

    .MuiButton-root:hover {
        background-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return darken(0.1, c);
        }};
    }

    .MuiFormLabel-root.Mui-focused {
        color: #0f0f0f;
    }

    .MuiOutlinedInput-root.MuiFocused {
        border-color: #0f0f0f !important;
    }
`;

export const Body = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
`;

export const Title = styled.div`
    max-width: 80%;
    width: 80%;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 7vh;
        color: #fff;
    }

    h2 {
        font-size: 3.5vh;
        font-weight: bold;
        color: #fff;
    }

    h3 {
        font-size: 2.5vh;
        font-weight: bold;
        color: #fff;
    }
`;

export const DarkDialog = styled.div`
    color: #fff !important;
    font-family: 'Saira Semi Condensed', sans-serif !important;

    .MuiDialogTitle-root {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 3vh;
            color: #fff !important;
        }
    }

    .MuiDialogContent-root {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 3vh;
            color: #fff !important;
        }
    }
`;

export const LogoArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10vh;
    max-height: 50px;

    img {
        max-width: 100%;
        max-height: 80%;

        &.pluginbot-logo {
            max-width: 60%;
            width: ${props => {
                const ps_logo = props.hasLogo;
                return ps_logo ? '20%' : 'auto';
            }};
        }

        &.pluginpace-logo {
            height: 10vh;
            max-width: 80%;
        }
    }
`;
