export function D3SessionStartMessage(message) {
    const data = message.data || {};
    const session = data.data || {};
    const { username, access_key } = session;

    return {
        inUse: true,
        session: {
            mode: username === 'public' ? 'pluginbot' : 'original',
            username,
            access_key,
            since: new Date(),
        },
    };
}

export function D3SessionEndMessage(message) {
    return {
        inUse: false,
        session: null,
    };
}

export function D3EndpointMessage(message) {
    const data = message.data || {};
    const { session, identity } = data;
    const robot_data = identity ? identity.robot : {};
    const robot_key = robot_data.key || '';

    return {
        ...(robot_key && { robot_key }),
        inUse: !!session,
        ...(!session && { session: null }),
    };
}

export function D3HeadInfoMessage(message) {
    const data = message.data || {};
    const { serial } = data;
    return {
        head_serial: serial,
    };
}

export function D3RemoteInfoMessage(message) {
    const data = message.data || {};
    const { d3_app_version, d3_app_deb } = data;
    return {
        remote_app_version: d3_app_version,
        remote_app_url: d3_app_deb,
    };
}

export function D3BaseVersionMessage(message) {
    const data = message.data || {};
    const { serial } = data;
    return {
        base_serial: serial,
    };
}

export function D3BaseStatusMessage(message) {
    const data = message.data || {};
    const { pole, kickstand, battery, charging, isLifted } = data;
    const robot_battery = {
        level: battery || 0,
        charging: charging || false,
    };

    return {
        pole,
        kickstand,
        charging,
        isLifted,
        battery: robot_battery,
    };
}

export function D3VolumeMessage(message) {
    const data = message.data || {};
    const { percent } = data;

    return percent;
}

export function D3SysInfoMessage(message) {
    const data = message.data || {};
    const { app_version } = data;
    const wlans =
        data && data.networkInterfaces && data.networkInterfaces.wlan0
            ? data.networkInterfaces.wlan0
            : [];
    const mainWlan = wlans[0];
    if (!mainWlan) {
        return {};
    }
    const { address, mac } = mainWlan;
    return {
        identifier: mac,
        address,
        app_version,
    };
}

export function D3NetworkInfoMessage(message) {
    const data = message.data || {};
    const { ssid, state, internalIp } = data;
    return {
        ssid,
        ip: internalIp,
        state,
    };
}

export function D3NetworkSignalMessage(message) {
    const data = message.data || {};
    const { link } = data;
    let signal_lvl = 0;

    if (link >= 60) {
        signal_lvl = 3;
    } else if (link >= 40 && link < 60) {
        signal_lvl = 2;
    } else if (link >= 20 && link < 40) {
        signal_lvl = 1;
    } else if (link < 20) {
        signal_lvl = 0;
    }

    return {
        level: signal_lvl,
    };
}

export function D3NetworkLocationMessage(message) {
    const data = message.data || {};
    return data;
}

export function D3UpdatingMessage(updating) {
    return {
        updating,
    };
}
