/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';

import { Button, Card, CardContent } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import logo from '~/assets/logo-white.png';
import api from '~/services/pluginbot-api';

import { Container, Head, Body, Footer, Title } from '../styles';

export default function RobotInvite({ match }) {
    const { invite_id } = match.params;

    const [invite, setInvite] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    function requestError(error) {
        if (error.response) {
            setErrorMsg({
                tag: `errors.${error.response.data.code}`,
            });
        } else if (error.request) {
            setErrorMsg({
                tag: 'errors.request',
            });
        } else {
            setErrorMsg({
                tag: 'errors.unknown',
            });
        }
    }

    async function loadInvite() {
        await api
            .get(`apps/invite_manager/invites/${invite_id}`)
            .then(response => {
                setInvite(response.data);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadInvite();
    }, []);

    const visualSettings = invite ? invite.pluginspace : {};
    const colors = visualSettings.theme;

    function renderError() {
        return (
            <Title className="mb-3">
                <LocaleMessage msg={errorMsg.tag} />
            </Title>
        );
    }

    function renderMessage() {
        if (!invite) return null;

        const { end_date, url } = invite;

        const now = new Date();
        const has_ended = isAfter(now, new Date(end_date));

        return (
            <div className="centered mb-3">
                <p className="mb-5 text">
                    <LocaleMessage msg="app.invites.end.thanks" />
                </p>
                {has_ended ? (
                    <p className="mb-5 text">
                        <LocaleMessage msg="app.invites.end.see_you" />
                    </p>
                ) : (
                    <>
                        <p className="mb-3 text">
                            <LocaleMessage msg="app.invites.end.access_again" />
                        </p>
                        <Button
                            fullWidth
                            color="primary"
                            className="p-3 colored-button"
                            variant="contained"
                            size="large"
                            disabled={has_ended}
                            onClick={() => {
                                return window.open(url, '_self');
                            }}
                        >
                            <LocaleMessage msg="messages.back" />
                        </Button>
                    </>
                )}
            </div>
        );
    }

    return (
        <Container visual={{ ...visualSettings, colors }}>
            <Head>
                <img
                    src={visualSettings.logo ? visualSettings.logo.url : logo}
                    alt="Pluginspace Logo"
                    title="Pluginpace Logo"
                />
            </Head>
            <Body colors={colors}>
                <Card className="main-obj mt-5 mb-5">
                    <CardContent className="full-size centered">
                        {errorMsg ? (
                            renderError()
                        ) : (
                            <>
                                <Title className="mb-3">
                                    <LocaleMessage msg="app.invites.end.title" />
                                </Title>

                                {renderMessage()}
                            </>
                        )}
                    </CardContent>
                </Card>
            </Body>
            <Footer>
                <span>powered by</span>
                <img src={logo} alt="Pluginbot Logo" title="Pluginbot Logo" />
            </Footer>
        </Container>
    );
}

RobotInvite.propTypes = {
    match: PropTypes.object.isRequired,
};
