import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: ${props =>
        props.screen && props.screen.isLandscape ? '1.5% 7.5%' : '0px'};
`;

export const ChatDialog = styled.div`
    width: 100%;
    height: 100%;
    font-size: medium;
    border: 1px solid ${props => props.theme.palette.primary.main};

    .chat-main {
        display: flex;
        margin-bottom: -15px;
        flex-direction: row;
        min-height: 50vh;
        height: -webkit-fill-available;
    }

    .chat-side {
        .chat-side-title {
            font-weight: bold;
            color: #fff !important;
            margin-top: 15px;
            width: 100%;
            text-align: center;
        }

        .chat-side-content {
            padding: 30px 15px 30px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            img {
                max-width: 85%;
                max-height: 85%;
            }
        }
    }

    .chat-main-header {
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        padding: 16px 16px 16px 32px;
        color: #fff;

        .chat-main-header-info {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .chat-contact-name {
            font-weight: bold;
        }
    }

    .chat-main-scroll {
        background-color: #000;
    }

    .chat-main-content {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .sender-user .bubble {
        padding: 12px 20px;
        background-color: ${props => {
            const color = props.theme.palette.primary.main;
            return lighten(0.07, color);
        }};
        color: #fff !important;

        .text-muted {
            color: #ccc !important;
            font-size: small !important;
        }
    }

    .sender-robot .bubble {
        padding: 12px 20px;
        background-color: #fff !important;
        color: #333 !important;

        .text-muted {
            color: ${() => {
                return lighten(0.1, '#333');
            }} !important;
            font-size: small !important;
        }
    }

    .chat-main-footer {
        border-top: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        z-index: 1000;
    }

    textarea {
        border-radius: 1.5vh;
        padding: 1vh 2vh 1vh 2vh;
        font-size: large;
    }

    .MuiIconButton-label {
        color: ${props => props.theme.palette.primary.main};
    }

    .MuiSelect-select.MuiSelect-select {
        padding-right: 0px;
    }

    .MuiSelect-icon {
        display: none;
    }
`;
