import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    font-size: 2vh;

    .enter-button {
        margin: 1vh 1vw;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 3vh;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5vh 1vw;
`;

export const RowButton = styled.div`
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vh;
`;

export const Spacer = styled.div`
    padding: 5px;
`;
