/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md';

import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import NoRobot from '~/components/NoRobot';

import logo from '~/assets/logo-white.png';
import {
    D3EndpointMessage,
    D3SysInfoMessage,
    D3HeadInfoMessage,
    D3BaseStatusMessage,
    D3BaseVersionMessage,
    D3NetworkInfoMessage,
    D3NetworkSignalMessage,
    D3NetworkLocationMessage,
    D3RemoteInfoMessage,
} from '~/lib/Double3/handleMessages';
import { getUrl } from '~/lib/link';

import { Container, Body, LogoArea, Title } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogText = withStyles(theme => ({
    root: {
        color: '#ddd',
    },
}))(DialogContentText);

const RESET_APP = 'https://d3.doublerobotics.com';
const PLUGINBOT_APP = `${getUrl()}/double_3`;
const mb = '20px';

const d3_events = [
    'DREndpointModule.status',
    'DRAPI.status',
    'DRAPI.remoteConfiguration',
    'DRBase.status',
    'DRBase.version',
    'DRNetwork.info',
    'DRNetwork.signal',
    'DRNetwork.location',
    'DRSystem.systemInfo',
    'DRCalibration.values',
    'DRSpeaker.volume',
];

export default function Double3() {
    const { DRDoubleSDK } = window;

    function resetWatchdog() {
        if (DRDoubleSDK) {
            DRDoubleSDK.resetWatchdog();
        }
    }

    const isD3Available = !!DRDoubleSDK;
    const isDev =
        process.env.REACT_APP_ENV_VERSION === 'local' ||
        process.env.REACT_APP_ENV_VERSION === 'development';

    const [settingsOpen, setSettingsOpen] = useState(false);
    const [messageDialog, setMessageDialog] = useState(false);

    const [robotStatus, setRobotStatus] = useState({});
    const [robotSettings, setRobotSettings] = useState(null);
    const [visualSettings, setVisualSettings] = useState({
        logo: null,
    });

    const stateRef = useRef().current || {};

    if (!isD3Available && !isDev) {
        console.error('ERROR: DRDoubleSDK not found');
    }

    resetWatchdog();

    useEffect(() => {}, []);

    function sendCommand(command, options = {}) {
        if (DRDoubleSDK) {
            return DRDoubleSDK.sendCommand(command, options);
        }
        return false;
    }

    sendCommand('gui.watchdog.disallow');
    sendCommand('base.kickstand.deploy');

    function requestInfo() {
        sendCommand('endpoint.enable');
        sendCommand('endpoint.requestIdentity', { requestSetupLink: false });
        sendCommand('endpoint.requestModuleStatus');
        sendCommand('system.requestInfo');
        sendCommand('base.requestStatus');
        sendCommand('base.requestVersion');
        sendCommand('network.requestLocation');
        sendCommand('network.requestInfo');
        sendCommand('api.requestLocalConfiguration');
        sendCommand('api.requestRemoteConfiguration');
        sendCommand('calibration.requestValues');
    }

    function subscribeD3() {
        if (DRDoubleSDK) {
            if (DRDoubleSDK.isConnected()) {
                // Class.key of subscribed events
                DRDoubleSDK.sendCommand('events.subscribe', {
                    events: d3_events,
                });

                return true;
            }
        }
        return false;
    }

    function exitApp() {
        sendCommand('endpoint.driverSidebar.stop');
    }

    function installApp() {
        sendCommand('api.setConfig', {
            key: 'STANDBY_URL',
            value: PLUGINBOT_APP,
        });
        sendCommand('system.restartService');
    }

    function uninstallApp() {
        sendCommand('api.setConfig', {
            key: 'STANDBY_URL',
            value: RESET_APP,
        });
        sendCommand('system.restartService');
    }

    // Stablishes the connection and re-call the function if need to reconnect
    function onConnect() {
        if (DRDoubleSDK) {
            subscribeD3();
            // Requests the Robot Infos
            requestInfo();
            return true;
        }

        // console.log('onConnected > timeout');
        return window.setTimeout(onConnect, 100);
    }

    function connectSDK() {
        if (DRDoubleSDK) {
            onConnect();
            // Try to connect to DRDoubleSDK and set auto reconnection
            DRDoubleSDK.on('disconnect', () => {
                onConnect();
            });
        } else if (isDev) {
            // Debug mode
            const r_ids = [2];
            setRobotSettings({
                identifier: `robot_test_${
                    r_ids[Math.floor(Math.random() * r_ids.length)]
                }`,
                address: '127.0.0.1',
            });
        }
    }

    // EFFECTS
    function saveStatus(key, values) {
        const ref_state = stateRef[key] || {};
        const new_state = { ...ref_state, ...values };
        stateRef[key] = new_state;
        setRobotStatus(stateRef);

        if (key === 'settings') {
            return setRobotSettings(new_state);
        }
        return true;
    }

    // Robot Settings
    function handleSysInfoMessage(message) {
        const sys_info = D3SysInfoMessage(message);
        return saveStatus('settings', sys_info);
    }

    function handleHeadInfoMessage(message) {
        const head_info = D3HeadInfoMessage(message);
        return saveStatus('head', head_info);
    }

    function handleBaseVersionMessage(message) {
        const base_version = D3BaseVersionMessage(message);
        return saveStatus('settings', base_version);
    }

    function handleRemoteInfoMessage(message) {
        const remote_info = D3RemoteInfoMessage(message);
        return saveStatus('settings', remote_info);
    }

    function handleEndpointMessage(message) {
        const endpoint_status = D3EndpointMessage(message);
        return saveStatus('software', endpoint_status);
    }

    // Hardware Status
    function handleBaseStatusMessage(message) {
        const base_status = D3BaseStatusMessage(message);
        return saveStatus('hardware', base_status);
    }

    // Connection Status
    function handleNetworkInfoMessage(message) {
        const network = D3NetworkInfoMessage(message);
        return saveStatus('connection', network);
    }

    function handleNetworkSignalMessage(message) {
        const signal = D3NetworkSignalMessage(message);
        return saveStatus('connection', signal);
    }

    function handleNetworkLocationMessage(message) {
        const location = D3NetworkLocationMessage(message);
        return saveStatus('location', location);
    }

    const handleMsg = {
        'DRAPI.remoteConfiguration': message =>
            handleRemoteInfoMessage(message),
        'DRSystem.systemInfo': message => handleSysInfoMessage(message),
        'DRCalibration.values': message => handleHeadInfoMessage(message),
        'DRBase.version': message => handleBaseVersionMessage(message),
        'DRBase.status': message => handleBaseStatusMessage(message),
        'DRNetwork.info': message => handleNetworkInfoMessage(message),
        'DRNetwork.signal': message => handleNetworkSignalMessage(message),
        'DRNetwork.location': message => handleNetworkLocationMessage(message),
        'DREndpointModule.status': message => handleEndpointMessage(message),
    };

    window.onload = () => {
        resetWatchdog();
        sendCommand('gui.watchdog.disallow');
        connectSDK();
        if (DRDoubleSDK) {
            // console.log('Is Double Robot');
            DRDoubleSDK.on('event', message => {
                const { class: m_class, key } = message;
                const m_key = `${m_class}.${key}`;
                // console.log({ key: m_key, message });
                const handleMessageFunction = handleMsg[m_key];
                if (handleMessageFunction) {
                    handleMessageFunction(message);
                }
            });
        }
        // window.setInterval(() => {
        //     resetWatchdog();
        // }, 1500);
    };

    const handleClickOpen = () => {
        setSettingsOpen(true);
    };

    const handleClose = () => {
        setSettingsOpen(false);
        setMessageDialog(null);
    };

    function renderPopUpMessage(open, message) {
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        width: '70%',
                        backgroundColor: '#111',
                        boxShadow: 'none',
                        color: '#fff',
                        paddingBottom: '10px',
                    },
                }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#fff !important',
                        fontSize: '2.5vh',
                    }}
                >
                    {message.title || null}
                    <IconButton
                        onClick={handleClose}
                        style={{
                            marginRight: '0px',
                            paddingRight: '0px',
                            color: '#ddd',
                        }}
                    >
                        <MdClose />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    className="mb-3"
                    style={{
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {message.body}
                </DialogContent>
            </Dialog>
        );
    }

    function renderButton(label_key, onClick) {
        return (
            <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className="mt-3 mb-3 p-3"
                onClick={onClick}
            >
                <LocaleMessage msg={label_key} />
            </Button>
        );
    }

    return (
        <Container visual={visualSettings}>
            {isD3Available || isDev ? (
                <>
                    {messageDialog
                        ? renderPopUpMessage(true, messageDialog)
                        : null}
                    <Body>
                        <LogoArea hasLogo={false}>
                            <img
                                className="pluginbot-logo"
                                src={logo}
                                alt="Pluginbot Logo"
                                title="Pluginbot Logo"
                            />
                        </LogoArea>

                        <Title>
                            {renderButton('setup.double_3.install', () => {
                                installApp();
                            })}
                            {renderButton('setup.double_3.uninstall', () => {
                                uninstallApp();
                            })}
                            {renderButton('setup.exit', () => {
                                exitApp();
                            })}
                        </Title>
                    </Body>
                </>
            ) : (
                <NoRobot />
            )}
        </Container>
    );
}
