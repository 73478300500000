/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import PropTypes from 'prop-types';

import Splash from '~/components/Splash/Outside';

import AppLocale from '~/lngProvider';

import { Container, Body } from './styles';

const lang_locale = {
    en_US: 'en',
    en_GB: 'enGB',
    pt_BR: 'br',
    es_ES: 'es',
};

export default function RobotApp({ match, children, selector }) {
    const { app_id } = match.params;

    const isLoading = false;
    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height');
    }

    const all_states = useSelector(state => state[selector] || {});
    const all_settings = all_states.settings || {};
    const app_settings =
        app_id && all_settings[app_id] ? all_settings[app_id] : null;
    const language =
        app_settings && app_settings.language ? app_settings.language : 'en_US';
    const locale =
        language && lang_locale[language] ? lang_locale[language] : 'en';

    const currentAppLocale = AppLocale[locale] || {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
    };

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <>
                <ToastContainer
                    toastClassName="toast-container"
                    autoClose={false}
                    position="top-center"
                    theme="dark"
                />
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <Container>
                            <Body className="app-main-content">{children}</Body>
                        </Container>
                    </>
                )}
            </>
        </IntlProvider>
    );
}

RobotApp.propTypes = {
    match: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
    selector: PropTypes.string,
};

RobotApp.defaultProps = {
    selector: '',
};
