/* eslint-disable no-lonely-if */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AWSMqttClient from 'aws-mqtt';
import AWS from 'aws-sdk/global';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import languages from '~/components/LanguageSwitcher/data';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import { verifyRobot, robotSession, authRC3 } from '~/lib/pluginbot-robot';
import {
    sendStatusMessage,
    getEventBody,
    sendEventsMessage,
    sendNotificationsMessage,
    sendAlertsMessage,
} from '~/lib/RC3';
import api from '~/services/pluginbot-api';
import {
    updateRobotID,
    updateRobotPassword,
} from '~/store/modules/robot/actions';
import { switchLanguage } from '~/store/modules/settings/actions';
import {
    updateAppStatus,
    updateCallSession,
} from '~/store/modules/telepresence_app/actions';

import Logo from '../Logo';
import { Title } from '../styles';
import TelepresenceApp from './Telepresence';

const D3_APP_VERSION = process.env.REACT_APP_TELEPRESENCE_APP_VERSION;

const UPDATE_TIME = 15 * 1000;

const BATTERY_THRESHOLD = 15;

const STATUS_CONNECTED = 'connected';
const STATUS_DISCONNECTED = 'disconnected';
const STATUS_OCCUPIED = 'occupied';
const STATUS_ALERT = 'alert';

// const EVENT_DISCONNECT = 'disconnected';
const EVENT_CONNECT = 'connected';
const EVENT_DISCONNECT_LWT = 'disconnected-LWT';
const EVENT_START_CALL = 'start_call';
const EVENT_END_CALL = 'end_call';

const NOTIFICATION_UPDATE_INFO = 'update_info';
const NOTIFICATION_READY = 'ready';

export default function Content({
    settings,
    robotStatus,
    sendCommand,
    setVisualSettings,
    exitApp,
    uninstallApp,
    setPopUp,
    notifyRC3Status,
    setValues,
}) {
    const dispatch = useDispatch();
    const app_settings = useSelector(state => state.telepresence_app || null);
    const RC3Status = app_settings.status;
    const callSession = app_settings.session ? app_settings.session : null;

    const softwareStatus = robotStatus.software || {};

    const [ID, setID] = useState(null);
    const [token, setToken] = useState(null);

    const [appStatus, setAppStatus] = useState('start');
    const [appConfig, setAppConfig] = useState(null);
    const [telepresenceStatus, setTelepresenceStatus] = useState({
        inUse: !!callSession,
        session: callSession,
    });

    const [RC3Config, setRC3Config] = useState(null);
    const [RC3Connected, setRC3Connected] = useState(false);
    const [RC3Client, setRC3Client] = useState(null);

    const [lastMessage, setLastMessage] = useState({});
    const [lastNotification, setLastNotification] = useState(null);
    const [lastAction, setLastAction] = useState(null);

    const stateApp = useRef().current || {
        status: 'start',
    };

    function requestError(error) {
        if (error.response) {
            const message = <h1>{error.response.data.error}</h1>;
            return setPopUp({
                title: <LocaleMessage msg="errors.generic" />,
                body: message,
            });
        }
        return setPopUp({
            title: <LocaleMessage msg="errors.generic" />,
            body: '',
        });
    }

    function handleRC3Connection(connected) {
        notifyRC3Status(connected);
        setRC3Connected(connected);
    }

    function sendAlert(alert) {
        sendAlertsMessage(RC3Client, RC3Config.topics, ID, alert);
    }

    function sendStatus(new_status) {
        if (!RC3Config) return;

        const app_status = {
            id: appConfig ? appConfig.id : null,
            name: appConfig ? appConfig.name : null,
            version: D3_APP_VERSION,
            type: 'telepresence',
        };

        const volumeStatus =
            robotStatus && robotStatus.volume ? robotStatus.volume : {};
        const hardwareStatus =
            robotStatus && robotStatus.hardware ? robotStatus.hardware : {};
        const connectionStatus =
            robotStatus && robotStatus.connection ? robotStatus.connection : {};
        const locationStatus =
            robotStatus && robotStatus.location ? robotStatus.location : {};

        let updated_status = new_status;
        const battery = hardwareStatus.battery || {};
        const { level, charging } = battery;

        if (new_status === STATUS_CONNECTED) {
            if (!charging && level <= BATTERY_THRESHOLD) {
                updated_status = STATUS_ALERT;
            }
        }

        const operation =
            telepresenceStatus && telepresenceStatus.session
                ? telepresenceStatus.session
                : null;

        const r_status = {
            status: updated_status,
            connection: connectionStatus,
            battery,
            geolocation: locationStatus,
            volume: volumeStatus,
            hardware: {
                pole: hardwareStatus.pole,
                kickstand: hardwareStatus.kickstand === 1,
            },
            ...(operation && {
                telepresence: operation,
            }),
        };

        sendStatusMessage(
            RC3Client,
            RC3Config.topics,
            {
                ...ID,
                application: app_status,
            },
            r_status
        );
    }

    function sendNotification(notification) {
        if (RC3Config) {
            sendNotificationsMessage(
                RC3Client,
                RC3Config.topics,
                ID,
                notification
            );
        }
    }

    function sendEvent(rc3_client, rc3_event) {
        const data = {
            ...ID,
            application: appConfig,
        };

        if (RC3Config) {
            sendEventsMessage(rc3_client, RC3Config.topics, data, rc3_event);
        }
    }

    function saveStatus(new_status) {
        dispatch(updateAppStatus(new_status));
        sendStatus(new_status);
    }

    function saveRC3Status(type) {
        switch (type) {
            case EVENT_START_CALL: {
                saveStatus(STATUS_OCCUPIED);
                break;
            }
            case EVENT_END_CALL: {
                saveStatus(STATUS_CONNECTED);
                setTelepresenceStatus({});
                dispatch(updateCallSession(null));
                break;
            }
            default:
                break;
        }
    }

    // SETTINGS
    function handleRC3Client(client, topics) {
        if (client) {
            client.on('connect', () => {
                // console.log('connect');
                Object.keys(topics).forEach(t => {
                    const topic = topics[t];
                    client.subscribe(topic);
                });

                handleRC3Connection(true);
                sendEvent(client, { type: EVENT_CONNECT });
                if (RC3Status !== STATUS_OCCUPIED) {
                    saveStatus(STATUS_CONNECTED);
                }
            });
            client.on('close', () => {
                client.end();
                saveStatus(STATUS_DISCONNECTED);
                handleRC3Connection(false);
            });
            client.on('offline', () => {
                saveStatus(STATUS_DISCONNECTED);
                handleRC3Connection(false);
            });
            client.on('message', (topic_ui8, message_ui8) => {
                try {
                    const topic = JSON.stringify(topic_ui8);
                    const message = JSON.parse(JSON.stringify(message_ui8));
                    const buffer = Buffer.from(message.data).toString('utf8');
                    const message_data = JSON.parse(buffer);
                    setLastMessage({
                        topic,
                        message: message_data,
                    });
                } catch (err) {
                    console.error('Message Error');
                }
            });
        }
        return false;
    }

    function connectRC3Robot() {
        // console.log('connectRC3Robot');
        const data = {
            ...ID,
            application: appConfig,
        };

        if (RC3Config) {
            try {
                const { region, endpoint, client_id, auth, topics } = RC3Config;
                const subscribeTopics = topics.subscribe || {};
                const publishTopics = topics.publish || {};
                const eventsTopic = publishTopics.events;

                AWS.config.region = region;
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityId: auth.identity_id,
                    Logins: auth.logins,
                });

                const ts = Date.now();
                const client = `${client_id}_${ts}`;

                const r_client = new AWSMqttClient({
                    keepalive: 30,
                    region: AWS.config.region,
                    credentials: AWS.config.credentials,
                    endpoint,
                    expires: 30,
                    clientId: client,
                    will: {
                        topic: eventsTopic,
                        payload: JSON.stringify(
                            getEventBody(data, { type: EVENT_DISCONNECT_LWT })
                        ),
                        qos: 1,
                        retain: false,
                    },
                });

                setRC3Client(r_client);
                handleRC3Client(r_client, subscribeTopics);
                return true;
            } catch (err) {
                console.error(err);
            }
        }
        setRC3Client(null);
        return false;
    }

    function setLanguage(code = 'en_US') {
        const lang = languages.find(l => {
            return l.code === code;
        });
        dispatch(switchLanguage(lang));
    }

    async function startRC3(sessionToken) {
        setAppStatus('connecting_rc3');

        await authRC3({
            url: `rc3/auth/robot?type=identity`,
            body: null,
            settings: {
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                },
            },
        })
            .then(response => {
                const { data } = response;
                handleRC3Connection(false);
                setRC3Config({
                    region: data.region,
                    endpoint: data.endpoint,
                    client_id: data.client_id,
                    auth: data.auth,
                    topics: data.topics,
                });
            })
            .catch(() => {
                saveStatus(STATUS_DISCONNECTED);
            });
        stateApp.status = 'ready';
        setAppStatus('ready');
    }

    function parseSession(session, sync_rc3) {
        const { token: sessionToken, app_config } = session;
        if (!sessionToken) {
            return setAppStatus('waiting_app');
        }

        setToken(sessionToken);
        setVisualSettings(app_config);
        setAppConfig(app_config);
        setLanguage(app_config.language);

        if (app_config.password) {
            dispatch(updateRobotPassword(app_config.password));
            delete app_config.password;
        }
        if (app_config.screensaver_timeout) {
            sendCommand('screensaver.allow', {
                seconds: app_config.screensaver_timeout,
            });
        } else {
            sendCommand('screensaver.allow', {
                seconds: 24 * 60 * 60,
            });
            sendCommand('screensaver.prevent');
        }
        if (sync_rc3) {
            return startRC3(sessionToken);
        }
        return setTimeout(() => {
            setAppStatus('ready');
        }, 500);
    }

    async function startSession(data, sync_rc3) {
        if (!data) {
            return setAppStatus('verify');
        }
        setAppStatus('loading');
        dispatch(updateRobotID(data.code));
        const { pluginspace, code } = data;

        const session_body = {
            code,
            pluginspace,
            application_type: 'telepresence',
        };
        await robotSession({
            url: `auth/robot`,
            body: session_body,
        })
            .then(response => {
                const auth = response.data;
                parseSession(auth, sync_rc3);
            })
            .catch(() => {
                setTimeout(() => {
                    setAppStatus('waiting_app');
                }, 500);
            });
        return true;
    }

    function enterBindStatus(code) {
        setAppStatus('bind');
        setID({
            code,
        });
    }

    async function registerRobot(identifier) {
        stateApp.status = 'register';
        const data = {
            type: 'double_3',
            identifier,
            settings: {
                extras: {
                    double_robot_id: settings.robot_key,
                },
            },
        };
        await api
            .post(`robots/register`, data)
            .then(response => {
                const robot = response.data;
                enterBindStatus(robot.code);
            })
            .catch(() => {});
    }

    async function verifySettings(identifier, silent = true) {
        stateApp.status = 'verify';
        if (!silent) {
            setAppStatus('verify');
        }
        await verifyRobot({
            url: `robots/verify/double_3/${identifier}`,
        })
            .then(response => {
                const { data } = response;
                const status = data && data.status ? data.status : 'frozen';

                setID(data);

                if (data.error) {
                    if (data.code && data.code === 'register_robot') {
                        return registerRobot(identifier);
                    }
                }

                switch (status) {
                    case 'removed':
                        setAppStatus('removed');
                        break;
                    case 'frozen':
                    case 'blocked':
                        setAppStatus('frozen');
                        break;
                    case 'active':
                        setAppStatus('waiting_app');
                        if (data.pluginspace) {
                            startSession(data, true);
                        } else if (
                            data.message_code &&
                            data.message_code === 'bind_robot'
                        ) {
                            enterBindStatus(data.code);
                        }
                        break;
                    default:
                        break;
                }

                return true;
            })
            .catch(() => {
                stateApp.status = 'not_connected';
                setAppStatus('not_connected');
            });
    }

    // EVENTS
    async function resyncApp() {
        setAppStatus('verify');
        setLastNotification(null);
        setID(null);
        setAppConfig(null);
        window.location.reload();
    }

    function sendCallEvent(t_status) {
        if (!t_status) {
            return false;
        }
        const isStart = t_status.inUse;
        const event_type = isStart ? EVENT_START_CALL : EVENT_END_CALL;
        const session = t_status.session || {};
        sendEvent(RC3Client, {
            type: event_type,
            data: session,
            ended_by: session.ended_by,
            ...(event_type && { status: 'connected' }),
        });
        saveRC3Status(event_type);
        return true;
    }

    // TELEPRESENCE EVENTS
    function setupCall(type, data) {
        const isStart = type === 'start';
        const telep_status = {
            inUse: isStart,
            session: data,
        };
        dispatch(updateCallSession(data));
        setTelepresenceStatus(telep_status);

        // console.log(`CALL EVENT - IS START: ${isStart} - RC3: ${RC3Status} `);
        // console.log({ data });

        if (!isStart && RC3Status === STATUS_OCCUPIED) {
            dispatch(updateCallSession(null));
            sendCommand(`base.kickstand.deploy`, {});
        }
        if (data) {
            setTimeout(() => {
                sendCallEvent(telep_status);
            }, 1000);
        }
    }

    async function getCallData(data) {
        let call_data = {
            mode: 'unknown',
            reference: '',
        };
        const date = new Date();
        const { session } = data;
        if (session) {
            if (session.mode === 'pluginbot') {
                const identifier = `vp_${session.access_key}`;

                const call = await api
                    .get(`apps/telepresence/calls?identifier=${identifier}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(response => {
                        return response.data;
                    })
                    .catch(() => {
                        return {};
                    });

                call_data = {
                    reference: call.id || '',
                    mode: call.mode || 'unknown',
                    user: call.user_name || '',
                    user_id: call.user_id || '',
                };
            } else {
                const o_call = await api
                    .post(`apps/telepresence/calls/original`, session, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(response => {
                        return response.data;
                    })
                    .catch(() => {
                        return {};
                    });

                call_data = {
                    reference: o_call.call_id || '',
                    mode: 'original',
                };
            }
        }
        call_data.since = date;

        return setupCall('start', call_data);
    }

    async function handleRTC(new_status) {
        const { inUse } = new_status;
        if (inUse === undefined) return false;
        // console.log(`IN USE: ${inUse} - RC3: ${RC3Status}`);
        // console.log({ callSession });

        if (RC3Status !== STATUS_DISCONNECTED) {
            if (inUse) {
                // console.log('Is in use');
                if (RC3Status !== STATUS_OCCUPIED) {
                    if (callSession) {
                        // console.log('Has call session!!!');
                        // console.log('Should warn call start!!!');
                        return setupCall('start', callSession);
                    }

                    // console.log('Doesnt have call session!!!');
                    return getCallData(new_status);
                }
            } else {
                // console.log('Is not in use');
                if (RC3Status === STATUS_OCCUPIED) {
                    // console.log('Has call session!!!');
                    // console.log('End Call');
                    return setupCall('end', callSession);
                }
                if (telepresenceStatus.inUse) {
                    return setupCall('end', null);
                }
            }
        }
        return false;
    }

    function handleEndCallAction(message) {
        dispatch(updateCallSession({ ...callSession, ended_by: message.user }));
        return sendCommand('endpoint.session.end', {});
    }

    // MESSAGES
    function handleRC3Actions(message = {}) {
        const { action_type, data } = message;
        setLastAction(message);

        switch (action_type) {
            case 'set_values':
                setValues(data);
                setTimeout(() => {
                    sendNotification({
                        type: NOTIFICATION_READY,
                    });
                }, 500);
                return true;
            case 'sync_app':
                return resyncApp();
            case 'end_call':
                return handleEndCallAction(message);
            case 'request_call':
            default:
                return null;
        }
    }

    function handleRC3Notifications(message = {}) {
        const { sender, type } = message;
        if (sender === 'user' && type === 'request_info') {
            sendStatus(RC3Status);
        }
        return setLastNotification(sender !== 'robot' ? message : null);
    }

    function handleRC3Message() {
        const { topic, message } = lastMessage;
        if (topic) {
            if (topic.includes('/actions')) {
                return handleRC3Actions(message);
            }
            if (topic.includes('/notifications')) {
                return handleRC3Notifications(message);
            }
        }
        return false;
    }

    // =========================================================================
    // EFFECTS
    // =========================================================================

    // START APP
    useEffect(() => {
        if (appStatus === 'start') {
            if (settings && settings.identifier) {
                verifySettings(settings.identifier, false);
            }
        }
    }, [appStatus, settings]);

    // WAIT FOR PLUGINSPACE BINDING
    useEffect(() => {
        if (appStatus === 'bind' && settings && settings.identifier) {
            const interval = setInterval(() => {
                verifySettings(settings.identifier);
            }, UPDATE_TIME);
            return () => clearInterval(interval);
        }
        return true;
    }, [appStatus]);

    // CONNECT TO RC3
    useEffect(() => {
        if (RC3Config && (!RC3Connected || RC3Status === STATUS_DISCONNECTED)) {
            connectRC3Robot();
        }
    }, [RC3Config, RC3Connected]);

    useEffect(() => {
        sendNotification({
            type: NOTIFICATION_READY,
        });
        sendNotification({
            type: NOTIFICATION_UPDATE_INFO,
        });
        sendStatus(RC3Status);
        const interval = setInterval(() => {
            sendStatus(RC3Status);
        }, UPDATE_TIME);
        return () => clearInterval(interval);
    }, [RC3Client, RC3Status, robotStatus]);

    useEffect(() => {
        if (RC3Status !== STATUS_DISCONNECTED) {
            handleRTC(softwareStatus);
        }
    }, [RC3Status, softwareStatus.inUse]);

    useEffect(() => {
        sendStatus(RC3Status);
    }, [RC3Status, telepresenceStatus]);

    useEffect(() => {
        handleRC3Message();
    }, [lastMessage]);

    // =========================================================================

    function renderButton(label_key, onClick) {
        return (
            <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className="mt-3 mb-3"
                onClick={onClick}
            >
                <LocaleMessage msg={label_key} />
            </Button>
        );
    }

    function renderRetryButton() {
        return renderButton('app.double_3.content.retry', () => {
            resyncApp();
        });
    }

    function renderNotConnected() {
        return (
            <Title>
                <h2 className="mb-5">
                    <LocaleMessage msg="app.double_3.content.not_connected" />
                </h2>
                {renderRetryButton()}
            </Title>
        );
    }

    function renderVerify() {
        return (
            <Title>
                <h2>
                    <LocaleMessage msg="app.double_3.content.verifying" />
                </h2>
            </Title>
        );
    }

    function renderRegister() {
        return (
            <Title>
                <h2>
                    <LocaleMessage msg="app.double_3.content.register" />
                </h2>
            </Title>
        );
    }

    function renderBind() {
        return (
            <Title>
                <h2 className="mb-5">
                    <LocaleMessage msg="app.double_3.content.bind" />
                </h2>
                {ID ? <h1 className="mb-5">{ID.code}</h1> : null}
                {renderRetryButton()}
            </Title>
        );
    }

    function renderRemoved() {
        return (
            <Title>
                {ID ? <h1>{ID.code}</h1> : null}
                <h3 className="mt-5 mb-5">
                    <LocaleMessage msg="app.double_3.removed" />
                </h3>
                {renderRetryButton()}
                {renderButton('app.double_3.content.uninstall', () => {
                    uninstallApp();
                })}
            </Title>
        );
    }

    function renderBlocked() {
        return (
            <Title>
                {ID ? <h1>{ID.code}</h1> : null}
                <h2 className="mt-5 mb-5">
                    <LocaleMessage msg="app.double_3.blocked" />
                </h2>
                {renderRetryButton()}
                {renderButton('app.double_3.content.exit', () => {
                    exitApp();
                })}
            </Title>
        );
    }

    function renderWaiting() {
        return (
            <Title>
                {ID ? <h2 className="mb-5">{ID.code}</h2> : null}
                <h2 className="mb-5">
                    <LocaleMessage msg="app.double_3.content.setup" />
                </h2>
                {renderRetryButton()}
            </Title>
        );
    }

    function renderMainApp() {
        return (
            <TelepresenceApp
                token={token}
                status={RC3Status}
                settings={appConfig}
                requestError={error => requestError(error)}
                sendAlert={alert => sendAlert(alert)}
                sendNotification={notification =>
                    sendNotification(notification)
                }
                lastAction={lastAction}
                lastNotification={lastNotification}
                telepresenceStatus={telepresenceStatus}
                clearActions={() => setLastAction(null)}
                RC3Connected={RC3Status === STATUS_CONNECTED}
            />
        );
    }

    const content = {
        not_connected: renderNotConnected(),
        verify: renderVerify(),
        register: renderRegister(),
        bind: renderBind(),
        waiting_app: renderWaiting(),
        loading: <Splash />,
        connecting_rc3: <Splash />,
        ready: renderMainApp(),
        frozen: renderBlocked(),
        blocked: renderBlocked(),
        removed: renderRemoved(),
    };

    return (
        <>
            <Logo
                darkLogo={
                    appConfig && appConfig.dark_logo
                        ? appConfig.dark_logo
                        : false
                }
                psLogo={appConfig && appConfig.logo ? appConfig.logo : null}
                onLogoClick={() => resyncApp()}
            />
            {content[appStatus] ? content[appStatus] : null}
        </>
    );
}

Content.propTypes = {
    settings: PropTypes.object,
    robotStatus: PropTypes.object,
    sendCommand: PropTypes.func,
    setVisualSettings: PropTypes.func,
    exitApp: PropTypes.func.isRequired,
    uninstallApp: PropTypes.func.isRequired,
    setPopUp: PropTypes.func.isRequired,
    notifyRC3Status: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
};

Content.defaultProps = {
    settings: {},
    robotStatus: {},
    sendCommand: () => {},
    setVisualSettings: () => {},
};
