import { darken } from 'polished';
import styled from 'styled-components';

export const TopBarContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-height: 50px;
    width: 100%;
    padding: 5px;

    img {
        max-width: 100%;
        max-height: 40px;
    }

    .pluginbot-logo {
        padding: 10px 0px;
    }

    svg {
        max-height: 30px;

        :hover {
            cursor: pointer;
        }
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .MuiButton-root {
        background-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return c;
        }};
    }

    .MuiButton-root:hover {
        background-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return darken(0.1, c);
        }};
    }
`;

export const MainArea = styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .portrait {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
    }
`;
