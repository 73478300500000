/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';

import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';

import { Button, Card, CardContent, TextField } from '@material-ui/core';

import languages from '~/components/LanguageSwitcher/data';
import LocaleMessage from '~/components/LocaleMessage';

import logo from '~/assets/logo-white.png';
import api from '~/services/pluginbot-api';
import { switchLanguage } from '~/store/modules/settings/actions';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';

import { Container, Head, Body, Footer, Title, SubTitle } from '../styles';

const date_opt = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
};

const time_opt = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
};

export default function RobotInvite({ match }) {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const { locale } = settings;

    const { invite_id } = match.params;

    const [isLoading, setIsLoading] = useState(true);
    const [joining, setJoining] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [invite, setInvite] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [now, setNow] = useState(new Date());
    const [identification, setIdentification] = useState('');

    function getTime() {
        setNow(new Date());
    }

    function requestError(error) {
        if (error.response) {
            setErrorMsg({
                tag: `errors.${error.response.data.code}`,
            });
        } else if (error.request) {
            setErrorMsg({
                tag: 'errors.request',
            });
        } else {
            setErrorMsg({
                tag: 'errors.unknown',
            });
        }
        setIsLoading(false);
    }

    function setLanguage(code = 'pt_BR') {
        const lang = languages.find(l => {
            return l.code === code;
        });
        dispatch(switchLanguage(lang));
    }

    async function loadInvite() {
        setIsLoading(true);
        await api
            .get(`apps/invite_manager/invites/${invite_id}`)
            .then(response => {
                const inv = response.data;
                const { language } = inv;
                setLanguage(language);

                const has_ended = isAfter(new Date(), new Date(inv.end_date));
                setIsAvailable(!has_ended);
                setInvite(inv);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function joinInvite() {
        setJoining(true);

        const data = { identification };

        await api
            .post(`apps/invite_manager/invites/${invite_id}/join`, data)
            .then(response => {
                const { join } = response.data;
                if (!join) {
                    requestError({ code: 'invalid_call' });
                }

                switch (join.type) {
                    case 'url': {
                        const { url } = join;
                        return window.open(url, '_self');
                    }
                    default:
                        return false;
                }
            })
            .catch(error => requestError(error));
        setJoining(false);
    }

    useEffect(() => {
        if (isAvailable) {
            const interval = setInterval(() => {
                getTime();
            }, 5 * 1000);
            return () => clearInterval(interval);
        }
        return () => {};
    }, [locale, isAvailable]);

    useEffect(() => {
        loadInvite();
    }, []);

    const visualSettings = invite ? invite.pluginspace : {};
    const invIdentification = invite ? invite.identification : {};
    const colors = visualSettings.theme;

    function clearError() {
        setErrorMsg(null);
        setIdentification('');
    }

    function renderError() {
        return (
            <>
                <Title className="mb-3">
                    <LocaleMessage msg={errorMsg.tag} />
                </Title>
                <div style={{ minWidth: '50%' }}>
                    <Button
                        fullWidth
                        color="primary"
                        className="p-3 colored-button"
                        variant="contained"
                        size="large"
                        onClick={() => clearError()}
                    >
                        <LocaleMessage msg="messages.retry" />
                    </Button>
                </div>
            </>
        );
    }

    function renderIdentification() {
        const required = invIdentification && invIdentification.required;
        return required ? (
            <div className="centered mt-3 mb-3">
                <span className="mb-3 text">{invIdentification.text}</span>

                <TextField
                    fullWidth
                    variant="outlined"
                    value={identification}
                    inputProps={{ style: { textAlign: 'center' } }}
                    onChange={event => {
                        setIdentification(event.target.value);
                    }}
                />
            </div>
        ) : null;
    }

    function renderInviteDate(start, end) {
        const s_date = start.date;
        const s_time = start.time;
        const e_date = end.date;
        const e_time = end.time;

        const same_date = s_date === e_date;
        return (
            <>
                {same_date ? (
                    <>
                        <p className="mb-1">{s_date.toUpperCase()}</p>
                        <p className="mb-1">{`${s_time} - ${e_time}`}</p>
                    </>
                ) : (
                    <>
                        <p className="mb-1">{`${s_date.toUpperCase()} - ${s_time}`}</p>
                        <p className="mb-1">{`${e_date.toUpperCase()} - ${e_time}`}</p>
                    </>
                )}

                {renderIdentification()}
            </>
        );
    }

    function renderInviteInfo() {
        if (!invite) return null;

        const { start_date, end_date } = invite;

        const start = GetDateTimeLabel(new Date(start_date), {
            format: locale,
            dateOptions: date_opt,
            timeOptions: time_opt,
        });

        const end = GetDateTimeLabel(new Date(end_date), {
            format: locale,
            dateOptions: date_opt,
            timeOptions: time_opt,
        });

        const has_started = isAfter(now, new Date(start_date));
        const has_ended = isAfter(now, new Date(end_date));

        return (
            <>
                <Title className="mb-3">
                    <LocaleMessage msg="app.invites.new.title" />
                </Title>
                <SubTitle>
                    {has_started ? (
                        <>
                            {!has_ended ? (
                                renderInviteDate(start, end)
                            ) : (
                                <p className="mb-1">
                                    <LocaleMessage msg="app.invites.expired" />
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <p className="mb-1">
                                <LocaleMessage msg="app.invites.soon" />
                            </p>
                            <p className="mb-1">{`${start.date.toUpperCase()} - ${
                                start.time
                            }`}</p>
                        </>
                    )}
                </SubTitle>
                <div style={{ minWidth: '50%' }}>
                    <Button
                        fullWidth
                        color="primary"
                        className="p-3 colored-button"
                        variant="contained"
                        size="large"
                        disabled={!isAvailable || !has_started || has_ended}
                        onClick={() => joinInvite()}
                    >
                        {joining ? (
                            <LocaleMessage msg="messages.joining" />
                        ) : (
                            <LocaleMessage msg="messages.enter" />
                        )}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <Container visual={{ ...visualSettings, colors }}>
            <Head>
                <img
                    src={visualSettings.logo ? visualSettings.logo.url : logo}
                    alt="Pluginspace Logo"
                    title="Pluginpace Logo"
                />
            </Head>
            <Body colors={colors}>
                <Card className="main-obj mt-5 mb-5">
                    <CardContent className="full-size centered">
                        {errorMsg ? (
                            renderError()
                        ) : (
                            <>
                                {isLoading ? (
                                    <ReactLoading
                                        type="bars"
                                        color="#c8c8c8"
                                        height={80}
                                        width={80}
                                    />
                                ) : (
                                    renderInviteInfo()
                                )}
                            </>
                        )}
                    </CardContent>
                </Card>
            </Body>
            <Footer>
                <span>powered by</span>
                <img src={logo} alt="Pluginbot Logo" title="Pluginbot Logo" />
            </Footer>
        </Container>
    );
}

RobotInvite.propTypes = {
    match: PropTypes.object.isRequired,
};
