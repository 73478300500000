/* eslint-disable no-lonely-if */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import AWSMqttClient from 'aws-mqtt';
import AWS from 'aws-sdk/global';
import PropTypes from 'prop-types';

import { AppBar, Container, Toolbar } from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';
import UserInfo from '~/components/UserInfo';

import pluginbot_logo from '~/assets/logo-white.png';
import SD02Img from '~/assets/robots/sd02.png';
import { sendUserNotificationMessage } from '~/lib/RC3';
import pluginbot_api from '~/services/pluginbot-api';
import pluginbot_synkar from '~/services/pluginbot-synkar';

import { Title } from '../../styles';
import Alerts from '../Alerts';
import RobotList from './List';
import RobotDelivery from './Robot';
import { Body, TopBarContent } from './styles';

const imgs = {
    synkar_sd02: SD02Img,
};

// const STATUS_CONNECTED = 'connected';
// const STATUS_DISCONNECTED = 'disconnected';
// const STATUS_OCCUPIED = 'occupied';
// const STATUS_ALERT = 'alert';

// const EVENT_CONNECT = 'connected';
// const EVENT_DISCONNECT = 'disconnected';
// const NOTIFICATION_UPDATE_INFO = 'update_info';
// const NOTIFICATION_READY = 'ready';

export default function Content({
    visual,
    screen,
    session,
    application,
    setPopUp,
    exitSession,
}) {
    const auth = session && session.token ? session.token : '';

    const auth_header = {
        headers: {
            Authorization: `Bearer ${auth}`,
        },
    };

    const [appStatus, setAppStatus] = useState('list');
    const [appConfig, setAppConfig] = useState({});
    const [robotList, setRobotList] = useState([]);
    const [robotsStatus, setRobotsStatus] = useState({});

    const [currRobot, setCurrRobot] = useState({});

    const [RC3Connected, setRC3Connected] = useState(false);
    const [RC3Client, setRC3Client] = useState(null);
    const [RC3UserSettings, setRC3UserSettings] = useState(null);

    const [lastMessage, setLastMessage] = useState({});

    const [lastNotification, setLastNotification] = useState(null);
    const [lastAlert, setLastAlert] = useState(null);

    function requestError(error) {
        if (error.response) {
            const message = (
                <span style={{ color: '#fff !important' }}>
                    {error.response.data.error}
                </span>
            );

            if (error.response.status === 401) {
                exitSession(null);
            }
            return setPopUp({
                title: <LocaleMessage msg="errors.generic" />,
                body: message,
            });
        }
        return setPopUp({
            title: <LocaleMessage msg="errors.generic" />,
            body: '',
        });
    }

    async function authUser() {
        await pluginbot_api
            .post(`rc3/auth/application`, {}, auth_header)
            .then(response => {
                const { data } = response;

                setRC3UserSettings({
                    region: data.region,
                    endpoint: data.endpoint,
                    client_id: data.client_id,
                    auth: data.auth,
                    topics: data.topics,
                });
            })
            .catch(error => {
                requestError(error);
            });
    }

    async function loadApplication() {
        await pluginbot_api
            .get(`apps/indoor_delivery/${application}/settings`, auth_header)
            .then(response => {
                const { data } = response;
                const app = {
                    pluginspace_id: data.pluginspace_id,
                    group_id: data.group_id,
                    ...data.application,
                };
                setRobotList(data.robots || []);
                setAppConfig(app);
                setAppStatus('home');
            })
            .catch(error => {
                requestError(error);
            });
    }

    async function loadServiceRobots() {
        const url = `robots?group=${appConfig.group_id}`;
        const status_list = { ...robotsStatus };

        await pluginbot_synkar
            .get(url, auth_header)
            .then(response => {
                const synkar_robots = response.data;
                Object.keys(synkar_robots).forEach(r => {
                    const s_robot = synkar_robots[r];
                    status_list[s_robot.id] = {
                        status: s_robot.status,
                        data: s_robot.data,
                    };
                });
                setRobotsStatus(status_list);
            })
            .catch(() => {});
        return true;
    }

    function handleRC3Connection(connected) {
        setRC3Connected(connected);
    }

    function sendNotification(notification_data) {
        if (RC3Client && RC3Connected) {
            sendUserNotificationMessage(
                RC3Client,
                RC3UserSettings.topics,
                {
                    robot_id: currRobot ? currRobot.id : '',
                },
                notification_data
            );
        }
    }

    // RC3 SETTINGS
    function connectRC3User() {
        // console.log('Trying to connect...');
        const {
            region,
            endpoint,
            client_id,
            auth: rc3_auth,
            topics,
        } = RC3UserSettings;
        try {
            AWS.config.region = region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityId: rc3_auth.identity_id,
                Logins: rc3_auth.logins,
            });
            const ts = Date.now();
            const client = `${client_id}_${ts}`;

            const u_client = new AWSMqttClient({
                keepalive: 30,
                region: AWS.config.region,
                credentials: AWS.config.credentials,
                endpoint,
                expires: 30,
                clientId: client,
            });

            u_client.on('connect', () => {
                // console.log('Connected');
                const sub = topics.subscribe || [];
                sub.forEach(t => {
                    u_client.subscribe(t);
                });
                handleRC3Connection(true);
            });

            setRC3Client(u_client);
        } catch (err) {
            setRC3Client(null);
        }
    }

    function handleRC3Client(client) {
        if (client) {
            client.on('close', () => {
                client.end();
                handleRC3Connection(false);
            });
            client.on('offline', () => {
                handleRC3Connection(false);
            });
            client.on('message', (topic_ui8, message_ui8) => {
                try {
                    const topic = JSON.stringify(topic_ui8);
                    const message = JSON.parse(JSON.stringify(message_ui8));
                    const buffer = Buffer.from(message.data).toString('utf8');
                    const message_data = JSON.parse(buffer);
                    setLastMessage({
                        topic,
                        message: message_data,
                    });
                } catch (err) {
                    console.log('Message Error');
                }
            });
        }
        return false;
    }

    // EVENTS
    async function resyncApp() {
        setAppStatus('verify');
        setLastNotification(null);
        setAppConfig(null);
        window.location.reload();
    }

    // MESSAGES
    function handleRC3Status(message = {}) {
        // console.log({ status_message: message });
        const { robot_id, status } = message;
        const last_status = robotsStatus[robot_id] || {};

        const status_data = { ...message };
        delete status_data.robot_id;
        delete status_data.status;

        setRobotsStatus({
            ...robotsStatus,
            [robot_id]: {
                ...last_status,
                status,
                data: status_data,
            },
        });
    }

    function handleRC3Actions(message = {}) {
        const { action_type } = message;

        switch (action_type) {
            case 'sync_app':
                return resyncApp();
            default:
                return null;
        }
    }

    function handleRC3Notifications(message = {}) {
        const { sender } = message;
        return setLastNotification(sender !== 'robot' ? message : null);
    }

    function handleRC3Alerts(message = {}) {
        return setLastAlert(message);
    }

    function handleRC3Message() {
        const { topic, message } = lastMessage;
        if (topic) {
            if (topic.includes('/status')) {
                return handleRC3Status(message);
            }
            if (topic.includes('/actions')) {
                return handleRC3Actions(message);
            }
            if (topic.includes('/alerts')) {
                return handleRC3Alerts(message);
            }
            if (topic.includes('/notifications')) {
                return handleRC3Notifications(message);
            }
        }
        return false;
    }

    function handleRobotSelection(r) {
        setCurrRobot(r);
        setAppStatus(r ? 'robot' : 'home');
    }

    // =========================================================================
    // EFFECTS
    // =========================================================================

    // START APP
    useEffect(() => {
        authUser();
        loadApplication();
    }, []);

    // CONNECT TO RC3
    useEffect(() => {
        if (RC3UserSettings && !RC3Connected) {
            connectRC3User();
        }
    }, [RC3UserSettings, RC3Connected]);

    useEffect(() => {
        handleRC3Client(RC3Client);
    }, [RC3Client]);

    useEffect(() => {
        handleRC3Message();
    }, [lastMessage]);

    // useEffect(() => {
    //     sendNotification({
    //         type: NOTIFICATION_READY,
    //     });
    //     sendNotification({
    //         type: NOTIFICATION_UPDATE_INFO,
    //     });
    //     sendStatus(RC3Status);
    //     const interval = setInterval(() => {
    //         sendStatus(RC3Status);
    //     }, update_time);
    //     return () => clearInterval(interval);
    // }, [RC3Client, RC3Status]);

    // =========================================================================

    function renderRetryButton() {
        return null;
        // return renderButton('app.double_3.content.retry', () => {
        //     resyncApp();
        // });
    }

    function renderNotConnected() {
        return (
            <Title>
                <h2 className="mb-5">
                    <LocaleMessage msg="app.double_3.content.not_connected" />
                </h2>
                {renderRetryButton()}
            </Title>
        );
    }

    function renderWaiting() {
        return (
            <Title>
                <h2 className="mb-5">
                    <LocaleMessage msg="app.double_3.content.setup" />
                </h2>
                {renderRetryButton()}
            </Title>
        );
    }

    function renderMainApp() {
        return currRobot ? (
            <RobotDelivery
                auth={auth_header}
                visual={visual}
                screen={screen}
                imgs={imgs}
                robot={currRobot}
                status={
                    robotsStatus[currRobot.id] ? robotsStatus[currRobot.id] : {}
                }
                application={appConfig}
                requestError={e => requestError(e)}
                clearRobotSelection={() => handleRobotSelection(null)}
                lastNotification={lastNotification}
                sendNotification={n => sendNotification(n)}
            />
        ) : null;
    }

    function renderList() {
        return (
            <RobotList
                imgs={imgs}
                robots={robotList}
                status={robotsStatus}
                application={appConfig}
                screen={screen}
                refreshStatus={() => loadServiceRobots()}
                requestError={e => requestError(e)}
                onRobotClick={r => handleRobotSelection(r)}
            />
        );
    }

    function renderTopBar() {
        return (
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters variant="dense">
                        <TopBarContent>
                            {appStatus === 'robot' ? (
                                <MdKeyboardArrowLeft
                                    size="40px"
                                    onClick={() => handleRobotSelection(null)}
                                />
                            ) : null}
                            <img
                                className="pluginbot-logo mt-1"
                                src={pluginbot_logo}
                                alt="Pluginbot Logo"
                                title="Pluginbot Logo"
                            />
                            <UserInfo
                                profile={
                                    session &&
                                    session.user &&
                                    session.user.profile
                                        ? session.user.profile
                                        : {}
                                }
                                onLogoutClick={() => exitSession()}
                                showStatus
                                status={
                                    RC3Connected ? 'connected' : 'disconnected'
                                }
                                showProfilePic={false}
                            />
                        </TopBarContent>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    const content = {
        not_connected: renderNotConnected(),
        waiting_app: renderWaiting(),
        home: renderList(),
        robot: renderMainApp(),
    };

    return (
        <>
            {renderTopBar()}
            <Alerts
                robots={robotList}
                lastAlert={lastAlert}
                lastNotification={lastNotification}
                sendNotification={n => sendNotification(n)}
                requestError={error => requestError(error)}
                setCurrRobot={r => {
                    setCurrRobot(r);
                    setAppStatus('robot');
                }}
            />
            <Body visual={visual}>
                <>
                    <CustomScrollbars className="py-3">
                        {content[appStatus] ? content[appStatus] : null}
                    </CustomScrollbars>
                </>
                {/* {RC3Connected ? (
                    <>{content[appStatus] ? content[appStatus] : null}</>
                ) : (
                    <ReactLoading
                        type="bars"
                        color="#fff"
                        height="10vh"
                        width="10vw"
                    />
                )} */}
            </Body>
        </>
    );
}

Content.propTypes = {
    session: PropTypes.object.isRequired,
    visual: PropTypes.object,
    screen: PropTypes.object.isRequired,
    application: PropTypes.string.isRequired,
    setPopUp: PropTypes.func.isRequired,
    exitSession: PropTypes.func.isRequired,
};

Content.defaultProps = {
    visual: {},
};
