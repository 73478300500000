import api from '~/services/pluginbot-api';

export async function verifyRobot(config) {
    const { url, test, default: data } = config;
    if (test) {
        return { data };
    }
    return api.get(url);
}

export async function robotSession(config) {
    const { url, body, test, default: data } = config;
    if (test) {
        return { data };
    }

    return api.post(url, body);
}

export async function authRC3(config) {
    const { url, body, settings, test, default: data } = config;
    if (test) {
        return {
            data,
        };
    }

    return api.post(url, body, settings);
}
