/* eslint-disable no-lonely-if */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';
import SVGMap from '~/components/SVGMap';

import { MainArea } from '../styles';
import { List, ListFilter, RobotCard, MapArea } from './styles';

export default function RobotList({
    imgs,
    screen,
    robots,
    status,
    application,
    onRobotClick,
    refreshStatus,
}) {
    const [robotHover, setRobotHover] = useState('');
    const [listFilter, setListFilter] = useState('all');
    const [filteredList, setFilteredList] = useState([]);

    const app_map = application.map || {};
    const has_map = app_map && app_map.file;

    function filterList() {
        if (listFilter === 'all') return setFilteredList(robots);
        const f_list = robots.filter(r => {
            const r_status = status[r.id] || {};
            return r_status.status && r_status.status !== 'disconnected';
        });

        return setFilteredList(f_list);
    }

    // =========================================================================
    // EFFECTS
    // =========================================================================

    // START APP
    useEffect(() => {
        if (application && application.id) {
            refreshStatus();
        }
    }, [application]);

    // Filter List
    useEffect(() => {
        filterList();
    }, [robots, listFilter, status]);

    // =========================================================================

    function renderRobotCard(r) {
        const r_status_obj = status[r.id] || {};
        const r_status = r_status_obj.status || 'disconnected';
        const r_data = r_status_obj.data || {};
        const r_type = r.type || {};
        const r_battery =
            r_data.battery && r_data.battery.level ? r_data.battery : null;

        return (
            <RobotCard
                className="row"
                onClick={() => onRobotClick(r)}
                status={r_status}
                key={r.id}
                onMouseOver={() => {
                    setRobotHover(r.id);
                }}
                onMouseLeave={() => setRobotHover('')}
            >
                <div className="col-4 center">
                    <img
                        src={imgs[r_type.slug]}
                        alt={r_type.name}
                        title={r_type.name}
                    />
                </div>
                <div className="col-8 column">
                    <span className="bold mb-3">
                        [{r.code}] {r.name}
                    </span>
                    {r_status !== 'disconnected' ? (
                        <>
                            <span className="mb-1">
                                <LocaleMessage msg="labels.battery" />
                                {': '}
                                {r_battery ? `${r_battery.level}%` : '---'}
                            </span>
                        </>
                    ) : (
                        <span>
                            <LocaleMessage msg="rc3.status.disconnected" />
                        </span>
                    )}
                </div>
            </RobotCard>
        );
    }

    function renderRobotList() {
        return (
            <>
                <ListFilter>
                    <FormControl className="status-filter">
                        <FormLabel>
                            <LocaleMessage msg="app.indoor_delivery.list.filter" />
                        </FormLabel>
                        <RadioGroup row value={listFilter}>
                            <FormControlLabel
                                value="all"
                                label={
                                    <LocaleMessage msg="app.indoor_delivery.list.filter.all" />
                                }
                                control={
                                    <Radio
                                        style={{ color: '#fff' }}
                                        size="small"
                                    />
                                }
                                onClick={() => setListFilter('all')}
                            />
                            <FormControlLabel
                                value="connected"
                                label={
                                    <LocaleMessage msg="app.indoor_delivery.list.filter.connected" />
                                }
                                control={<Radio style={{ color: '#fff' }} />}
                                onClick={() => setListFilter('connected')}
                            />
                        </RadioGroup>
                    </FormControl>
                </ListFilter>
                <List>
                    <CustomScrollbars className="p-3">
                        {filteredList.map(r => {
                            return renderRobotCard(r);
                        })}
                    </CustomScrollbars>
                </List>
            </>
        );
    }

    function renderMap() {
        const positions = filteredList.map(r => {
            const r_id = r.id;
            const r_status = status[r_id] || {};
            const r_data = r_status.data || {};
            const r_pose = r_data.pose ? r_data.pose : {};
            const r_position = r_pose.position ? r_pose.position : {};

            return {
                id: r.id,
                label: `[${r.code}]`,
                x: r_position.x,
                y: r_position.y,
            };
        });

        return (
            <MapArea>
                {has_map ? (
                    <SVGMap
                        height="100%"
                        image={app_map.file}
                        activePoint={robotHover}
                        setActivePoint={p => setRobotHover(p)}
                        points={positions}
                    />
                ) : (
                    <LocaleMessage msg="app.indoor_delivery.list.no_map" />
                )}
            </MapArea>
        );
    }

    return (
        <MainArea>
            <div
                className={`py-3 ${
                    screen.isLandscape ? 'col-12 row' : 'portrait'
                }`}
            >
                <div
                    className={`${
                        screen.isLandscape ? 'col-8' : 'pb-5'
                    } container`}
                >
                    {renderMap()}
                </div>
                <div
                    className={`${screen.isLandscape ? 'col-4' : ''} container`}
                >
                    {renderRobotList()}
                </div>
            </div>
        </MainArea>
    );
}

RobotList.propTypes = {
    imgs: PropTypes.object.isRequired,
    robots: PropTypes.array,
    application: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
    onRobotClick: PropTypes.func.isRequired,
    refreshStatus: PropTypes.func.isRequired,
    status: PropTypes.object,
};

RobotList.defaultProps = {
    robots: [],
    status: {},
};
