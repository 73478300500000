/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import defaultTheme from '~/styles/themes/defaultTheme';

import DefaultLayout from '../layouts/default';
import FutureLayout from '../layouts/future';
import RobotAppLayout from '../layouts/robot_app';

export default function RouteWrapper({
    component: Component,
    layout,
    selector,
    ...rest
}) {
    let Layout = DefaultLayout;

    switch (layout) {
        case 'robot_app':
            Layout = RobotAppLayout;
            break;
        case 'future':
            Layout = FutureLayout;
            break;
        case 'default':
        default:
            Layout = DefaultLayout;
            break;
    }

    const uiTheme = defaultTheme;

    const applyTheme = createMuiTheme(defaultTheme);
    document.body.classList.remove('rtl');

    return (
        <ThemeProvider theme={uiTheme}>
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Route
                        {...rest}
                        render={props => (
                            <Layout {...props} selector={selector}>
                                <Component {...props} />
                            </Layout>
                        )}
                    />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    );
}

RouteWrapper.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    isPrivate: PropTypes.bool,
    layout: PropTypes.string,
    selector: PropTypes.string,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
    layout: 'default',
    selector: 'settings',
};
