/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
        code: 'en_US',
        format: 'en-US',
    },
};

export default function settings(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@settings/SWITCH_LANGUAGE': {
                draft.locale = action.payload.locale;
                break;
            }
            default:
                return state;
        }
    });
}
