import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default reducers => {
    const persistedReducer = persistReducer(
        {
            key:
                '8427213f690110357e7a0486927c6c427e794fe676319bfead01d961e5b48d16',
            storage,
            whitelist: [
                'settings',
                'auth',
                'telepresence_app',
                'robot',
                'sd02_indoor',
            ],
        },
        reducers
    );

    return persistedReducer;
};
