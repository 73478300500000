/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import logoDark from '~/assets/logo-dark.png';
import logoWhite from '~/assets/logo-white.png';

import { LogoArea } from '../styles';

export default function Double3Header({ darkLogo, psLogo, onLogoClick }) {
    const delay = 300;
    const [click, setClick] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setClick(0);
        }, delay);

        // the duration between this click and the previous one
        // is less than the value of delay = double-click
        if (click === 2) {
            onLogoClick();
        }

        return () => clearTimeout(timer);
    }, [click]);

    const logo = darkLogo ? logoDark : logoWhite;

    return (
        <LogoArea hasLogo={!!psLogo}>
            <img
                className="pluginbot-logo"
                src={logo}
                alt="Pluginbot Logo"
                title="Pluginbot Logo"
                onClick={() => setClick(prev => prev + 1)}
            />
            {psLogo ? (
                <img
                    className="pluginspace-logo mt-3 mb-3"
                    src={psLogo.url}
                    alt="Pluginspace Logo"
                    title="Pluginspace Logo"
                />
            ) : null}
        </LogoArea>
    );
}

Double3Header.propTypes = {
    darkLogo: PropTypes.bool,
    psLogo: PropTypes.object,
    onLogoClick: PropTypes.func,
};

Double3Header.defaultProps = {
    darkLogo: false,
    psLogo: null,
    onLogoClick: () => {},
};
