import { darken } from 'polished';
import styled from 'styled-components';

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .big-text {
        text-align: center;
        font-size: ${props => {
            const screen = props.screen || {};
            const isLandscape = screen.isLandscape || false;
            const isBigScreen = screen.isBigScreen || false;
            return !isLandscape || isBigScreen ? '3vh' : '5vh';
        }};
    }
`;

export const MainArea = styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .portrait {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        color: #ffffff;

        .options {
            max-width: 800px;
            width: 80%;
        }
    }

    .robot-infos {
        padding-right: ${props => {
            const screen = props.screen || {};
            const isLandscape = screen.isLandscape || false;
            return isLandscape ? '0px' : '';
        }};
    }

    .alert {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
    }
`;

export const ActionsArea = styled.div`
    align-items: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    img {
        max-width: 80%;
    }
`;

export const RobotInfos = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    height: 100%;

    .item-column {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }

    .item-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .pluginbot-robot {
        padding-right: ${props => {
            const screen = props.screen || {};
            const isLandscape = screen.isLandscape || false;
            return isLandscape ? '0px' : '';
        }};
        img {
            max-height: ${props => {
                const screen = props.screen || {};
                const isLandscape = screen.isLandscape || false;
                return isLandscape ? '40%' : '25vh';
            }};
            max-width: 100%;
        }
    }
`;

export const ListsBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        color: #fff;
        font-weight: bold;
        height: 100%;
        font-size: ${props => {
            return props.bigFontSize ? '3.5vh' : '2vh';
        }};
    }

    .helper {
        font-size: ${props => {
            return props.bigFontSize ? '3vh' : '1.5vh';
        }};
    }

    .MuiButton-root {
        background-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return `${c} !important`;
        }};
    }
`;

export const MissionDialog = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Saira Semi Condensed', sans-serif !important;

    span {
        color: #fff !important;
    }

    .MuiCard-root {
        width: ${props => {
            const screen = props.screen || {};
            const isLandscape = screen.isLandscape || false;
            return isLandscape ? '60%' : '100%';
        }};
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: #000;
        border: 1px solid #ccc;

        .MuiCardActions-root {
            padding: 0px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .MuiButton-root {
                width: 40%;
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }

    .MuiCardHeader-root {
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .MuiCardHeader-content {
            color: #fff !important;
        }

        .MuiCardHeader-title {
            font-family: inherit;
            font-size: ${props => {
                const screen = props.screen || {};
                const isLandscape = screen.isLandscape || false;
                return isLandscape ? '3vh' : '1.5vh';
            }};
        }

        .MuiTypography-colorTextSecondary {
            color: #ccc !important;
        }

        span {
            text-align: center;
        }
    }

    .card-content {
        margin-top: 5vh;
        margin-bottom: 5vh;
        color: #fff !important;
        font-size: ${props => {
            return props.bigFontSize ? '10vh' : '7vh';
        }};
        font-weight: bold;
    }
`;

export const MissionLayout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    background: ${props => {
        const def_color = '#0f0f0f';
        const colors =
            props.visual && props.visual.colors ? props.visual.colors : {};
        const top_color = darken(0.3, colors.top || def_color);
        const bottom_color = darken(0.3, colors.bottom || def_color);
        return `linear-gradient(180deg, ${top_color}, ${bottom_color})`;
    }};

    .MuiTypography-root {
        font-family: inherit;
    }

    .mission-destination {
        font-size: 15vh;
        font-weight: bold;
    }
    .options {
        max-width: 800px;
        width: 90%;

        button {
            height: 100%;
        }
    }

    .MuiButton-outlined.Mui-disabled {
        background-color: #ffffff00;
        border-color: ${props => {
            const { colors } = props.visual;
            const c = colors ? colors.buttons : '#000';
            return `${c}`;
        }};

        .MuiButton-label {
            span {
                color: '#ccc';
            }
        }
    }
`;
