export function getStatusBody(data, robot_status) {
    const {
        status,
        battery,
        connection,
        geolocation,
        telepresence,
        volume,
        hardware,
    } = robot_status;
    const { location_id, zone_id, application } = data;

    return {
        robot_id: data.robot_id,
        status,
        battery,
        wifi: connection,
        application: {
            id: application ? application.id : null,
            name: application && application.name ? application.name : '',
            type: application && application.type ? application.type : '',
            version:
                application && application.version ? application.version : '',
        },
        geolocation,
        location: {
            id: location_id,
        },
        zone: {
            id: zone_id,
        },
        ...(volume && { volume }),
        ...(hardware && { hardware }),
        ...(telepresence && { telepresence }),
    };
}

export function sendStatusMessage(client, topics, data, robot_status) {
    const publishTopics = topics && topics.publish ? topics.publish : {};
    const statusTopic = publishTopics.status;

    const body = getStatusBody(data, robot_status);
    // console.log({ data, body });
    if (!client || !client.publish || !statusTopic) return null;
    try {
        return client.publish(statusTopic, JSON.stringify(body));
    } catch (err) {
        console.error(err);
    }
    return false;
}

export function getEventBody(data, event) {
    const { application } = data;
    const { type: event_type, data: event_data } = event;

    return {
        pluginspace_id: data.pluginspace,
        group_id: data.group_id,
        robot_id: data.robot_id,
        application_id: application ? application.id : null,
        event_type,
        data: event_data,
    };
}

export function sendEventsMessage(client, topics, data, event) {
    const publishTopics = topics && topics.publish ? topics.publish : {};
    const eventsTopic = publishTopics.events;

    const body = getEventBody(data, event);

    if (!client || !client.publish || !eventsTopic) return null;
    try {
        return client.publish(eventsTopic, JSON.stringify(body));
    } catch (err) {
        console.error(err);
    }
    return false;
}

export function getNotificationBody(sender, data, { type, body }) {
    return {
        sender,
        type,
        ...(sender === 'robot' && { robot_id: data.robot_id }),
        data: body || {},
    };
}

export function sendNotificationsMessage(client, topics, data, notification) {
    const publishTopics = topics && topics.publish ? topics.publish : {};
    const notificationsTopic = publishTopics.notifications;

    const body = getNotificationBody('robot', data, notification);

    if (!client || !client.publish || !notificationsTopic) return null;
    try {
        return client.publish(notificationsTopic, JSON.stringify(body));
    } catch (err) {
        console.error(err);
    }
    return false;
}

export function sendUserNotificationMessage(
    client,
    topics,
    data,
    notification
) {
    const publishTopics = topics && topics.publish ? topics.publish : [];
    const notificationsTopic = publishTopics.find(t => {
        return t.includes('/notifications');
    });

    const body = getNotificationBody('user', data, notification);

    if (!client || !client.publish || !notificationsTopic) return null;

    const robotTopic = notificationsTopic.replace('/+/', `/${data.robot_id}/`);

    try {
        return client.publish(robotTopic, JSON.stringify(body));
    } catch (err) {
        console.error(err);
    }
    return false;
}

export function getAlertBody(data, { id, type, body }) {
    return {
        type,
        robot_id: data.robot_id,
        alert_id: id,
        data: body || {},
    };
}

export function sendAlertsMessage(client, topics, data, alert) {
    const publishTopics = topics && topics.publish ? topics.publish : {};
    const alertsTopic = publishTopics.alerts;

    const body = getAlertBody(data, alert);

    if (!client || !client.publish || !alertsTopic) return null;

    try {
        return client.publish(alertsTopic, JSON.stringify(body));
    } catch (err) {
        console.error(err);
    }
    return false;
}
